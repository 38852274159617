import {
  SAVE_CHAT_HISTORY_REQUEST,
  SAVE_CHAT_HISTORY_SUCCESSED,
  SAVE_CHAT_HISTORY_FAILED,
  CLEAR_CHAT_HISTORY,
} from "../actionTypes";
import { mapsRef, databaseRef } from "../../core/firebase";

export const saveChatHistory = (id) => async (dispatch, getState) => {
  if (id) {
    dispatch(saveChatHistoryRequest());
    const chatDB = mapsRef.doc(id).collection("chat").orderBy("date", "desc");

    chatDB.onSnapshot(
      (snapshot) => {
        const res = [];
        snapshot.forEach((doc) => {
          res.push(doc.data());
        });
        dispatch(saveChatHistorySuccess(res));
      },
      (err) => {
        dispatch(saveChatHistoryFailed());
      }
    );
  }
};

export const saveChatHistoryRequest = () => ({
  type: SAVE_CHAT_HISTORY_REQUEST,
});

export const saveChatHistorySuccess = (payload) => ({
  type: SAVE_CHAT_HISTORY_SUCCESSED,
  payload,
});

export const saveChatHistoryFailed = () => ({
  type: SAVE_CHAT_HISTORY_FAILED,
});

export const sendMessage =
  (text, messageType = "message") =>
  (dispatch, getState) => {
    const { selectedMapId, selectedMapData } = getState().node;
    const { email, photoURL, displayName } = getState().auth;
    let msgData = null;
    var colors;
    selectedMapData.creator.map((item) => {
      if (item.email === email) {
        colors = item.color === undefined ? "" : item.color;
      }
      return colors;
    });
    if (messageType === "coach") {
      msgData = {
        text,
        username: ["Coach"],
        showedBy: [],
        type: messageType,
        userPicture: "/assets/ActivityCoach.png",
        date: new Date(),
        email: email,
        color: colors,
      };
    } else if (messageType === "message") {
      msgData = {
        text,
        username: [displayName],
        showedBy: [email],
        type: messageType,
        userPicture: photoURL,
        date: new Date(),
        email: email,
        color: colors,
      };
    } else if (messageType === "rewind") {
      msgData = {
        text,
        username: [displayName],
        showedBy: [],
        type: messageType,
        userPicture: null,
        date: new Date(),
        email: email,
        color: colors,
      };
    }
    if (email && text !== "") {
      mapsRef
        .doc(selectedMapId)
        .collection("chat")
        .add(msgData)
        .then((addedDoc) => {
          if (messageType === "rewind") {
            const currentMap = getState().node.selectedMapData;
            mapsRef
              .doc(selectedMapId)
              .collection("history")
              .add(currentMap)
              .then((historyDoc) => {
                addedDoc.update({ savedID: historyDoc.id });
              });
          }
        });
    }
  };

export const showAllMsg = () => (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  const { email } = getState().auth;
  const batch = databaseRef.batch();
  mapsRef
    .doc(selectedMapId)
    .collection("chat")
    .get()
    .then((chatQuery) => {
      chatQuery.forEach((doc) => {
        let { showedBy } = doc.data();
        if (showedBy && !showedBy.includes(email)) {
          showedBy.push(email);
        } else if (!showedBy || showedBy.length === 0) {
          showedBy = [email];
        }
        batch.update(doc.ref, { showedBy });
      });
      batch.commit();
    });
};

export const clearChatHistory = () => ({
  type: CLEAR_CHAT_HISTORY,
});
