import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // DialogActions,
  Container,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { CirclePicker, ChromePicker } from "react-color";
import {
  removePartipant,
  removePartipantById,
  updatePartipant,
  update_user_Color,
  update_chat_Icon_Color,
} from "../../redux/actions/nodeAction";
import { getAvatarChar, getAvatarColor } from "../../utils/global";
import { deleteMouseMovement } from "../../redux/actions/movementAction";
import "../../styles/components/SideBar/ParticipantComponent.scss";

function ParticipantComponent(props) {
  const { email } = useSelector((state) => state.auth);
  const { selectedMapData } = useSelector((state) => state.node);
  const dispatch = useDispatch();
  const [dropValue, setDrovalue] = useState(null);
  const [openDrop, openDropdown] = useState(null);
  const [openRoll, openRollmodel] = useState(null);
  const [open, setOpen] = useState(false);
  const [index, setMemberIndex] = useState(null);
  const [colorpicker, showColorPicker] = useState(false);
  const [color, setcolor] = useState(false);
  const usersMovements = useSelector((state) => state.movement);
  const auth = useSelector((state) => state.auth);
  const default_color = [
    "#0099fb",
    "#00bffe",
    "#00dbd8",
    "#00c960",
    "#3b3fce",
    "#ff4775",
    "#ffa300",
    "#ffd200",
    "#ff1920",
    // "#00ff00",
  ];

  useEffect(() => {
    selectedMapData !== null &&
      selectedMapData.creator.forEach((element) => {
        if (auth.email === element.email) {
          //localStorage.getItem("email")
          setDrovalue(element.dropvalue);
        }
        // selectedMapData.creator.map((value, index) {
        //   if (auth.email === value.email) {
        //     //localStorage.getItem("email")
        //     setDrovalue(value.dropvalue);
        //   }
        // });
      });
  }, [selectedMapData, auth.email]);
  useEffect(() => {
    const onScroll = () => {
      if (openDrop === "show") {
        document.getElementById("global-backdrop").style.display = "none";
        openDropdown(null);
        openRollmodel(null);
        showColorPicker(false);
      }
    };
    document
      .getElementById("global-backdrop")
      .addEventListener("click", onScroll);

    return () => {
      document
        .getElementById("global-backdrop")
        .removeEventListener("click", onScroll);
    };
  });
  const removeOne = () => {
    if (props.email === "") {
      dispatch(removePartipantById(props.random_id));
    } else {
      dispatch(removePartipant(props.email));
    }
    if (email === props.email) {
      props.backToHome();
    }
    document.getElementById("global-backdrop").style.display = "none";
  };
  const showDrondown = () => {
    if (openDrop == null) {
      document.getElementById("global-backdrop").style.display = "block";
      openDropdown("show");
    } else {
      document.getElementById("global-backdrop").style.display = "none";
      openDropdown(null);
    }
  };

  const showRollmodel = () => {
    openRoll == null ? openRollmodel("show") : openRollmodel(null);
  };
  const changedropvalue = (value, memberIndex) => {
    openRollmodel(null);
    if (value === "Owner") {
      setMemberIndex(memberIndex);
      setOpen(true);
    } else {
      dispatch(updatePartipant(props.email, value, memberIndex));
    }
  };
  const author = selectedMapData ? selectedMapData.author : null;
  // const useFirstname =
  //   localStorage.getItem(USE_FIRST_NAME) === "true" ? true : false;

  const handleChangeComplete = (color) => {
    dispatch(update_chat_Icon_Color(props.email, color.hex));
    // if (props.email === "") {
    //   dispatch(update_user_Color(props.email, props.random_id, color.hex));
    // } else {
    dispatch(update_user_Color(props.email, props.random_id, color.hex));
    // }
    if (selectedMapData && usersMovements && usersMovements.users) {
      var checkName = sessionStorage.getItem("guestLogin")
        ? `${sessionStorage.getItem("guestLogin")} (Guest)`
        : auth.displayName;
      const selectedUsersActions = usersMovements.users.filter((movement) => {
        return (
          selectedMapData &&
          movement.mapId === selectedMapData.id &&
          checkName === movement.userName
        );
      });

      if (selectedUsersActions?.length) {
        selectedUsersActions.forEach((action) => {
          dispatch(deleteMouseMovement(action.id));
        });
      }
    }
  };

  const showcolorpicker = () => {
    showColorPicker(true);
  };
  const dragcolor = (color) => {
    setcolor(color.hex);
    handleChangeComplete(color);
  };
  const owner_Transfer = () => {
    dispatch(updatePartipant(props.email, "Owner", index));
    setOpen(false);
  };
  const avatar = () => {
    return (
      <Avatar
        className={
          props?.color !== "" ? "" : `avt-${getAvatarColor(props?.displayName)}`
        }
        style={{
          backgroundColor: props?.color,
          color: "white",
          fontFamily: "Nunito !important",
        }}
      >
        {getAvatarChar(
          props?.displayName?.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\]/g,
            ""
          ),
          props?.useFirstname
        )}
      </Avatar>
    );
  };
  var checkModal =
    (props?.dropvalue === "Owner" && props?.email === auth?.email) ||
    author !== props?.email;

  return (
    <div className="participant-container">
      <div className="avatar-container">
        {props?.currentlyInMap ? (
          <Badge overlap="circular" variant="dot">
            {avatar()}
          </Badge>
        ) : (
          avatar()
        )}
        <span className="avatar-name">
          {props.useFirstname
            ? props.random_id === undefined
              ? props.displayName
              : `${props.displayName} (Guest)`
            : props.displayName
            ? props.random_id === undefined
              ? props.displayName.split(" ")[0]
              : `${props.displayName.split(" ")[0]} (Guest)`
            : ""}
          {props.dropvalue === undefined
            ? props.memberIndex === "0"
              ? " (owner)"
              : " (editor)"
            : " (" + props.dropvalue.toLowerCase() + ")"}
        </span>
      </div>
      {((dropValue === "Owner" || dropValue === undefined) &&
        sessionStorage.getItem("guestLogin") == null) ||
      (props.displayName === sessionStorage.getItem("guestLogin") &&
        props.dropvalue === "Owner") ? (
        <div className="dropdown">
          {checkModal && (
            <i
              className="fas fa-chevron-right"
              style={{ color: "#ffffff" }}
              onClick={() => showDrondown(this)}
            ></i>
          )}
          <div
            className={`dropdown-content ${openDrop}`}
            open={true}
            style={{ position: "fixed" }}
          >
            {props.dropvalue === "Owner" && props.email === auth.email ? (
              <>
                <a herf={() => false}>My color </a>
                <div className="color_picker">
                  <CirclePicker
                    width="auto"
                    colors={default_color}
                    circleSize={25}
                    circleSpacing={8}
                    onHide={true}
                    onChange={handleChangeComplete}
                  />
                  {/* <i className="fas fa-2x fa-plus-circle" onClick={showcolorpicker}></i> */}
                  <img
                    src="/assets/ico-add-color.svg"
                    alt=""
                    onClick={showcolorpicker}
                  />
                </div>
              </>
            ) : null}
            {author !== props.email && (
              <>
                <a herf={() => false} className="Observer">
                  {props.dropvalue === undefined ? " Owner" : props.dropvalue}{" "}
                  <i
                    className="fas fa-chevron-right"
                    onClick={() => showRollmodel(this)}
                  ></i>
                  <div className={`dropdown-content2 ${openRoll}`}>
                    <div
                      onClick={() => {
                        changedropvalue("Observer", props.memberIndex);
                      }}
                    >
                      Observer{" "}
                      <i className="far fa-question-circle tooltip">
                        <span className="tooltiptext">Can see the map</span>
                      </i>
                    </div>
                    <div
                      onClick={() => {
                        changedropvalue("Editor", props.memberIndex);
                      }}
                    >
                      Editor{" "}
                      <i className="far fa-question-circle tooltip">
                        <span className="tooltiptext">Can edit the map</span>
                      </i>
                    </div>
                    {props.random_id ? (
                      ""
                    ) : (
                      <div
                        onClick={() => {
                          changedropvalue("Owner", props.memberIndex);
                        }}
                      >
                        Owner{" "}
                        <i className="far fa-question-circle tooltip">
                          <span className="tooltiptext">
                            Can edit, duplicate and delete the map and manage
                            collaborators
                          </span>
                        </i>
                      </div>
                    )}
                  </div>
                </a>
                <hr />
                <a herf={() => false}>
                  {props.random_id ? (
                    <span onClick={removeOne}>Remove Guest</span>
                  ) : (
                    <span onClick={removeOne}>Remove collaborator</span>
                  )}
                </a>
              </>
            )}
            {colorpicker ? (
              <ChromePicker
                color={color}
                onChange={dragcolor}
                disableAlpha={true}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (props.dropvalue === "Observer" && props.email === auth.email) || //localStorage.getItem("email")
        (props.displayName === sessionStorage.getItem("guestLogin") &&
          props.dropvalue === "Observer") ? (
        <div className="dropdown">
          <i
            className="fas fa-chevron-right"
            style={{ color: "#ffffff" }}
            onClick={() => showDrondown(this)}
          ></i>
          <div
            className={`dropdown-content ${openDrop}`}
            open={true}
            style={{ position: "fixed" }}
          >
            <a herf={() => false}>My color </a>
            <div className="color_picker">
              <CirclePicker
                width="auto"
                colors={default_color}
                circleSize={25}
                circleSpacing={8}
                onHide={true}
                onChange={handleChangeComplete}
              />
              {/* <i className="fas fa-2x fa-plus-circle" onClick={showcolorpicker}></i> */}
              <img
                src="/assets/ico-add-color.svg"
                alt=""
                onClick={showcolorpicker}
              />
            </div>
            {colorpicker ? (
              <ChromePicker
                color={color}
                onChange={dragcolor}
                disableAlpha={true}
              />
            ) : (
              ""
            )}
            {props.displayName === sessionStorage.getItem("guestLogin") ? (
              ""
            ) : (
              <>
                <hr />
                <a herf={() => false}>
                  <span onClick={removeOne}>Leave map</span>
                </a>
              </>
            )}
          </div>
        </div>
      ) : (props.dropvalue === "Editor" && props.email === auth.email) || //localStorage.getItem("email")
        (props.displayName === sessionStorage.getItem("guestLogin") &&
          props.dropvalue === "Editor") ? (
        <div className="dropdown">
          <i
            className="fas fa-chevron-right"
            style={{ color: "#ffffff" }}
            onClick={() => showDrondown(this)}
          ></i>
          <div
            className={`dropdown-content ${openDrop}`}
            open={true}
            style={{ position: "fixed" }}
          >
            <a herf={() => false}>My color </a>
            <div className="color_picker">
              <CirclePicker
                width="auto"
                colors={default_color}
                circleSize={25}
                circleSpacing={8}
                onHide={true}
                onChange={handleChangeComplete}
              />
              {/* <i className="fas fa-2x fa-plus-circle" onClick={showcolorpicker}></i> */}
              <img
                src="/assets/ico-add-color.svg"
                alt=""
                onClick={showcolorpicker}
              />
            </div>
            {colorpicker ? (
              <ChromePicker
                color={color}
                onChange={dragcolor}
                disableAlpha={true}
              />
            ) : (
              ""
            )}
            {props.displayName === sessionStorage.getItem("guestLogin") ? (
              ""
            ) : (
              <>
                <hr />
                <a herf={() => false}>
                  <span onClick={removeOne}>Leave map</span>
                </a>
              </>
            )}
          </div>
        </div>
      ) : null}
      <Dialog
        BackdropProps={{ invisible: false }} //disableBackdropClick
        className="green-modal"
        maxWidth="xs"
        open={open}
      >
        <DialogTitle className="green-modal-header">
          {"Transfer Ownership"}
        </DialogTitle>
        <DialogContent className="green-modal-body">
          <p>
            {
              "Do you want to transfer ownership of this map? Owners can edit, duplicate and delete maps and manage Collaborators."
            }
          </p>
        </DialogContent>
        <Container className="green-modal-footer">
          <Button
            className="btn-outline"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            {"CANCEL"}
          </Button>
          <Button className="btn-green" onClick={() => owner_Transfer(this)}>
            {"TRANSFER"}
          </Button>
        </Container>
      </Dialog>
    </div>
  );
}

export default ParticipantComponent;
