import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./style.scss";
import EmojiObjectsTwoToneIcon from "@mui/icons-material/EmojiObjectsTwoTone";

const OpenNewMap = ({
  show,
  type,
  modifyHandal,
  openNewMapHandal,
  handleClose,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="dailog-box"
      >
        {type === "owner" && (
          <>
            <DialogTitle id="">{"Modify or open as a new map?"}</DialogTitle>
            <DialogContent className="dailog">
              <DialogContentText>
                {/* <DialogContentText> */}
                  Would you like to modify this template or open it as a new
                  map?
                {/* </DialogContentText> */}
                <div className="text-background">
                  {/* <div> */}
                    <EmojiObjectsTwoToneIcon className="EmojiObjectsTwoToneIcon-icon" />
                  {/* </div> */}
                  You can also duplicate a template to work on a copy of it
                  without changing the original.
                </div>
              </DialogContentText>
            </DialogContent>
            <div className="dailog-btn">
              <Button onClick={modifyHandal} className="green-btn">
                Modify template
              </Button>
              <Button onClick={openNewMapHandal} className="close-btn">
                Open as new map
              </Button>
            </div>
          </>
        )}
        {type === "nonOwner" && (
          <>
            <DialogTitle id="">{"How templates work"}</DialogTitle>
            <DialogContent className="dailog">
              <DialogContentText>
                You are opening a template as a new map. Your own copy of the
                map will be created and saved to your Map Library. The original
                template will stay in your Templates folder and will be
                unchanged.
              </DialogContentText>
            </DialogContent>
            <div className="dailog-btn">
              <Button onClick={modifyHandal} className="green-btn">
              Open as new map
              </Button>
              <Button onClick={handleClose} className="green-btn">
                Cancel
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};
export default OpenNewMap;
