import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // DialogActions,
  Container,
} from "@material-ui/core";

import "../../styles/components/Canvas/ConfirmDialog.scss";

const ConfirmDialog = ({
  open,
  title,
  description,
  okButtonLabel,
  cancelButtonLabel,
  onClosed,
  onConfirmed,
}) => {
  const onKeyEventHandler = (evt) => {
    if (!open) return;

    if (evt.keyCode === 13) {
      onConfirmed();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyEventHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      document.removeEventListener("keydown", onKeyEventHandler);
    };
  }, [open, onKeyEventHandler]);

  return (
    <Dialog
      style={{ zIndex: 1303 }}
      BackdropProps={{ invisible: false }} //disableBackdropClick
      className="green-modal"
      open={open}
      onClose={onClosed}
    >
      <DialogTitle className="green-modal-header">{title}</DialogTitle>
      <DialogContent className="green-modal-body">
        <p>{description}</p>
      </DialogContent>
      <Container className="green-modal-footer">
        <Button className="btn-outline" variant="outlined" onClick={onClosed}>
          {cancelButtonLabel}
        </Button>
        <Button className="btn-green" onClick={onConfirmed}>
          {okButtonLabel}
        </Button>
      </Container>
    </Dialog>
  );
};

export default ConfirmDialog;
