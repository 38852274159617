import { Button } from "@material-ui/core";
import {
  // Container,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";

const theme = createTheme({
  typography: {
    body1: {
      fontWeight: 500,
    },
  },
});

const LinkModal = ({ show, hide }) => {
  // const [data, setData] = useState();
  // function loadDoc() {
  //   try {
  //     const xhr = new XMLHttpRequest();
  //     if (xhr) {
  //       xhr.open(
  //         "GET",
  //         "http://cors.stage.eugeniuses.com/cauzality.com/troubleshooting/",
  //         { mode: 'cors' }
  //       );
  //     }
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           setData(xhr.response);
  //         }
  //       }
  //     };
  //     xhr.send();
  //   } catch (e) {
  //     console.log("rrrrrr", e);
  //   }
  // }

  // const dataHandler = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://cors.stage.eugeniuses.com/cauzality.com/troubleshooting/",
  //       {
  //         headers: {
  //           // "Content-Type": "application/json",
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     );
  //     const data = await response.text();
  //     setData(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // useEffect(() => {
  //   // dataHandler();
  //   setData(datas);
  // }, [data, datas]);
  return (
    <div className="LinkModal">
      <Dialog
        open={show}
        maxWidth="lg"
        aria-labelledby="responsive-dialog-title"
        className="link-dailog-box"
      >
        <DialogContent className="dailog" style={{ fontSize: 20 }}>
          {/* <div
            className="link-data"
            onLoad={loadDoc()}
            dangerouslySetInnerHTML={{ __html: data }}
          /> */}
          <div id="boxed-wrapper">
            <div id="wrapper" class="fusion-wrapper">
              <div
                id="home"
                style={{ position: "relative", top: "-1px" }}
              ></div>
              <div class="fusion-tb-header"></div>{" "}
              <div
                id="sliders-container"
                class="fusion-slider-visibility"
              ></div>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#384360"
                paddingTop={5}
                paddingBottom={5}
              >
                <section
                  class="avada-page-titlebar-wrapper--------"
                  aria-label="Page Title Bar"
                >
                  <div
                    class="
                fusion-page-title-bar fusion-page-title-bar-breadcrumbs
                 fusion-page-title-bar-center"
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        color="white"
                        fontWeight={800}
                        variant="h2"
                        component="h2"
                      >
                        Troubleshooting
                      </Typography>
                    </ThemeProvider>
                  </div>
                </section>
              </Grid>
              <Grid paddingX={5}>
                <main id="main" class="clearfix ">
                  <div class="fusion-row" style={{}}>
                    <section id="content" style={{ width: "100%" }}>
                      <div
                        id="post-1847"
                        class="post-1847 page type-page status-publish hentry"
                      >
                        <div class="post-content">
                          <div
                            class="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-flex-container nonhundred-percent-fullwidth non-hundred-percent-height-scrolling"
                            style={{
                              awbBorderSizesTop: 40,
                              awbBorderRadiusTopLeft: "0px",
                              awbBorderRadiusTopRight: "0px",
                              awbBorderRadiusBottomRight: "0px",
                              awbBorderRadiusBottomLeft: "0px",
                            }}
                          >
                            <div class="fusion-builder-row fusion-row fusion-flex-align-items-flex-start">
                              <div class="fusion-layout-column fusion_builder_column fusion-builder-column-0 fusion_builder_column_1_1 1_1 fusion-flex-column">
                                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                                  <div class="fusion-text fusion-text-1">
                                    <p style={{ color: "#333333" }}>
                                      If you are experiencing issues with
                                      Cauzality, please follow these steps in
                                      order until your problem is resolved:
                                    </p>
                                    <ol>
                                      <li>
                                        <strong style={{ color: "#333333" }}>
                                          Refresh the page.{" "}
                                        </strong>
                                        <span style={{ color: "#333333" }}>
                                          Refreshing the page will stop any
                                          problem-causing behavior and re-sync
                                          your browser with our server prompting
                                          it to load any new changes.
                                        </span>
                                      </li>
                                      <li>
                                        <strong style={{ color: "#333333" }}>
                                          Close all other windows, tabs and apps
                                          that aren’t being used.
                                        </strong>
                                        <span style={{ color: "#333333" }}>
                                          {" "}
                                          Cauzality is a powerful web
                                          application with dynamically updating
                                          content and it requires more memory
                                          than most websites. Because other
                                          windows, tabs and apps will consume
                                          memory, having too many other things
                                          open on your computer at the same time
                                          will use up the memory Cauzality needs
                                          to operate smoothly.
                                        </span>
                                      </li>
                                      <li>
                                        <strong style={{ color: "#333333" }}>
                                          Try a different browser.{" "}
                                        </strong>
                                        <span style={{ color: "#333333" }}>
                                          Before trying the two steps below,
                                          save time by opening Cauzality in a
                                          different modern browser such as
                                          Chrome (preferred), Edge or Safari.
                                        </span>
                                      </li>
                                      <li>
                                        <strong style={{ color: "#333333" }}>
                                          Update your browser.{" "}
                                        </strong>
                                        <span style={{ color: "#333333;" }}>
                                          Older versions of browsers will
                                          perform slower and can render certain
                                          elements incorrectly.
                                        </span>
                                      </li>
                                      <li>
                                        <strong style={{ color: "#333333" }}>
                                          Empty your browser’s cache.{" "}
                                        </strong>
                                        <span style={{ color: "#333333" }}>
                                          While cache is designed to speed
                                          things up online, storing too much
                                          cache will have the opposite effect.
                                          Be sure to empty your cache from time
                                          to time for optimal speed.
                                        </span>
                                      </li>
                                    </ol>
                                    <p style={{ color: "#0d1267" }}>
                                      <span
                                        style={{ color: "rgb(51, 51, 51)" }}
                                      >
                                        If you continue to experience issues
                                        after following these steps, use our “
                                      </span>
                                      Share Feedback” tool in the Help Menu
                                      <span
                                        style={{ color: "rgb(51, 51, 51)" }}
                                      >
                                        {" "}
                                        or send us a note at{" "}
                                      </span>
                                      <a
                                        style={{
                                          color: "rgb(51, 51, 51)",
                                          textDecoration: "none",
                                        }}
                                        href="mailto:hello@cauzality.com"
                                      >
                                        hello@cauzality.com
                                      </a>
                                      <span
                                        style={{ color: "rgb(51, 51, 51)" }}
                                      >
                                        {" "}
                                        and we will work to resolve your issue
                                        as soon as possible.
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </main>
              </Grid>
              <div class="fusion-tb-footer fusion-footer">
                <div class="fusion-footer-widget-area fusion-widget-area"></div>
              </div>
            </div>
          </div>
        </DialogContent>
        <div className="dailog-btn">
          <Button onClick={() => hide()} className="close-btn">
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default LinkModal;
