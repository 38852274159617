import {
  SAVE_RELEASENOTES_USER_SUCCESS,
  SAVE_RELEASENOTES_USER_FAILED
} from "../actionTypes";

const initialState = {
  releaseNotes_user:""
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_RELEASENOTES_USER_SUCCESS: {
      return {
        ...state,
        releaseNotes_user: payload,
      };
    }
    case SAVE_RELEASENOTES_USER_FAILED: {
      return {
        releaseNotes_user:null
      }
    }
    default: {
      return state;
    }
  }
}
