import React, { useState } from "react";
import { Menu, MenuItem, Divider, Link, makeStyles } from "@material-ui/core";
// import ReactAppzi from "react-appzi";
import { WhatNewDialog } from "../modal/index";
import "../../styles/components/TopBar/Help.scss";
import LinkModal from "./link";

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Help = ({ open, onClose }) => {
  const [show, setShow] = useState(false);
  const [openmodal, setOpen] = useState(false);
  const classes = useStyles();
  const whatNewHandleClose = () => {
    setShow(false);
  };
  const openLink = () => {
    setOpen(true);
  };
  const closemodal = () => {
    setOpen(false);
  };
  return (
    <>
      <Menu
        id="basic-menu"
        open={open}
        onClose={onClose}
        className="Help_popovers"
        disableUnderline={true}
      >
        {/* <MenuItem >Video Tutorials</MenuItem> */}
        <Link
          href="https://cauzality.com/mapping-guide"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <MenuItem>Mapping Guide</MenuItem>
        </Link>
        <Link
          href="https://cauzality.com/glossary"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <MenuItem>Glossary</MenuItem>
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            openLink();
            onClose();
          }}
          className={classes.link}
        >
          <MenuItem>Troubleshooting</MenuItem>
        </Link>
        {`${process.env.REACT_APP_PUBLIC_TYPE}` === "STAGING" && (
          <Link
            // href="https://cauzality.com/whats-new"
            // target="_blank"
            // rel="noopener noreferrer"
            onClick={() => {
              setShow(true);
              onClose();
            }}
            className={classes.link}
          >
            <MenuItem>What’s New</MenuItem>
          </Link>
        )}
        <Divider />
        <Link
          href={() => false}
          data-az-l="0f15699c-c830-44cf-a061-ef9411583c0f"
          onClick={() => onClose()}
          className={classes.link}
        >
          <MenuItem>Share Feedback</MenuItem>
        </Link>
      </Menu>
      <WhatNewDialog
        show={show}
        whatNewHandleClose={() => whatNewHandleClose()}
      />
      <LinkModal show={openmodal} hide={closemodal} />
    </>
  );
};

export default Help;
