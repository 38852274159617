import React from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";

import "../../styles/components/FeedbackBar/FeedbackLoopItem.scss";

const NotesItem = ({ data, index, onChangeVisibility }) => {
  const { notefocus } = useSelector((state) => state.note);
  const getUrl = () => {
    if (!notefocus) {
      return "/assets/ico-eye.svg";
    }
    if (
      notefocus?.findIndex(
        (fnote) => JSON.stringify(fnote.saveId) === JSON.stringify(data.saveId)
      ) < 0
    ) {
      return "/assets/ico-eye-close.svg";
    }

    return "/assets/ico-eye-blue.svg";
  };
  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={"feedback-item"}
        >
          <span className="feedback-item-number" {...provided.dragHandleProps}>
            {index + 1}
          </span>
          <div className="feedback-item-body">
            <p className="feedback-loop-name">{data.text}</p>
          </div>
          <div className="feedback-item-action">
            <Tooltip title="Show Loop" leaveDelay={200}>
              <button
                className="feedback-item-button"
                onClick={onChangeVisibility}
              >
                <img src={getUrl()} alt="center" style={{ width: 20 }} />
              </button>
            </Tooltip>
            <Tooltip title="Zoom to Fit" leaveDelay={200}>
              <button className="feedback-item-button">
                <img src="/assets/ico-center.svg" alt="center" />
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </Draggable>
  );
};
export default NotesItem;
