import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ReleaseNotes_Add_User } from "../../redux/actions/releaseNote_userAction";
import "./style.scss";
const Dailog_modal = ({ show, whatNewHandleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { releaseNotes } = useSelector((state) => state.releaseNotes);
  // const { releaseNotes_user } = useSelector((state) => state.releaseNotes_user);
  const user = useSelector((state) => state.auth);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const openLink = () => {
    onClose();
    window.open("https://cauzality.com/whats-new/", "_blank");
  };
  const onClose = () => {
    whatNewHandleClose();
    dispatch(ReleaseNotes_Add_User(user.uid, releaseNotes.id));
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={Boolean(show)}
        // onClose={() => onClose()}
        // maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        className="dailog-box"
      >
        <DialogTitle id="">{"What’s new"}</DialogTitle>

        <DialogContent className="dailog">
          {/* <DialogContentText> */}
          <p className="dailog-p">{releaseNotes?.version}</p>
          <ul>
            {releaseNotes?.releaseNotes?.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
          {/* </DialogContentText> */}
        </DialogContent>
        <div className="dailog-btn">
          <Button onClick={() => openLink()} className="green-btn">
            Read more
          </Button>
          <Button onClick={() => onClose()} className="close-btn">
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default Dailog_modal;
