export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESSED = "AUTH_LOGIN_SUCCESSED";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPLOAD_AVATAR_COLOR_SUCCESSED = "UPLOAD_AVATAR_COLOR_SUCCESSED";
export const UPLOAD_AVATAR_COLOR_FAILED = "UPLOAD_AVATAR_COLOR_FAILED";

export const MAP_LOAD_DATA_REQUEST = "MAP_LOAD_DATA_REQUEST";
export const MAP_LOAD_DATA_SUCCESSED = "MAP_LOAD_DATA_SUCCESSED";
export const MAP_LOAD_DATA_FAILED = "MAP_LOAD_DATA_FAILED";

export const SAVE_CURRENT_MAP_ID = "SAVE_CURRENT_MAP_ID";
export const SAVE_CURRENT_MAP_DATA = "SAVE_CURRENT_MAP_DATA";

export const UPLOAD_CURRENT_MAP_REQUEST = "UPLOAD_CURRENT_MAP_REQUEST";
export const UPLOAD_CURRENT_MAP_SUCCESS = "UPLOAD_CURRENT_MAP_SUCCESS";
export const UPLOAD_CURRENT_MAP_FAILED = "UPLOAD_CURRENT_MAP_FAILED";

export const SAVE_CHAT_HISTORY_REQUEST = "SAVE_CHAT_HISTORY_REQUEST";
export const SAVE_CHAT_HISTORY_SUCCESSED = "SAVE_CHAT_HISTORY_SUCCESSED";
export const SAVE_CHAT_HISTORY_FAILED = "SAVE_CHAT_HISTORY_FAILED";
export const CLEAR_CHAT_HISTORY = "CLEAR_CHAT_HISTORY";

export const SAVE_CYCLES = "SAVE_CYCLES";
export const SAVE_SELECTED_LOOP_AREA = "SAVE_SELECTED_LOOP_AREA";
export const SET_LOOP_VIEW = "SET_LOOP_VIEW";

export const SET_ACTIVE_MAP = "SET_ACTIVE_MAP";

export const UPDATE_USER_ACTION = "UPDATE_USER_ACTION";
export const SAVE_SELECTED_ACTION_ID = "SAVE_SELECTED_ACTION_ID";

export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_REQUEST_SUCCESS = "AUTH_SIGN_UP_REQUEST_SUCCESS";
export const AUTH_SIGN_UP_FAILED = "AUTH_SIGN_UP_FAILED";

export const AUTH_ADD_MEMBER_REQUEST = "AUTH_ADD_MEMBER_REQUEST";
export const AUTH_ADD_MEMBER_SUCCESS = "AUTH_ADD_MEMBER_SUCCESS";
export const AUTH_ADD_MEMBER_FAILED = "AUTH_ADD_MEMBER_FAILED";

export const COMPLETE_SIGNUP_REQUEST = "COMPLETE_SIGNUP_REQUEST";
export const COMPLETE_SIGNUP_SUCCESS = "COMPLETE_SIGNUP_SUCCESS";
export const COMPLETE_SIGNUP_FAILED = "COMPLETE_SIGNUP_FAILED";

export const CHECK_VALID_EMAIL_REQUEST = "CHECK_VALID_EMAIL_REQUEST";
export const CHECK_VALID_EMAIL_SUCCESS = "CHECK_VALID_EMAIL_SUCCESS";
export const CHECK_VALID_EMAIL_FAILED = "CHECK_VALID_EMAIL_FAILED";

export const SET_ADMIN_MODE = "SET_ADMIN_MODE";
export const SHOW_LEVERAGE_LOOP = "SHOW_LEVERAGE_LOOP";

export const CREATED_NEW_MAP = "CREATED_NEW_MAP";
export const CLEAR_CURRENT_MAP_DATA = "CLEAR_CURRENT_MAP_DATA";

export const CHANGE_COLOR_SYSTEM = "CHANGE_COLOR_SYSTEM";

export const SAVE_FOLDERS_REQUEST = "SAVE_FOLDERS_REQUEST";
export const SAVE_FOLDERS_SUCCESS = "SAVE_FOLDERS_SUCCESS";
export const SAVE_FOLDERS_FAILED = "SAVE_FOLDERS_FAILED";

export const SAVE_SELECTED_TAB = "SAVE_SELECTED_TAB";

export const CLEAR_MAPS = "CLEAR_MAPS";
export const SAVE_USER_SUBSCRIPTION = "SAVE_USER_SUBSCRIPTION";

export const SAVE_TOKEN = "SAVE_TOKEN";

export const CLONE_SUCCESS = "CLONE_SUCCESS";
//Template
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILED = "GET_TEMPLATE_FAILED";

//note
export const SAVE_NOTE_REQUEST = "SAVE_NOTE_REQUEST";
export const SAVE_NOTE_SUCCESS = "SAVE_NOTE_SUCCESS";
export const SAVE_NOTE_FAILED = "SAVE_NOTE_FAILED";
export const SAVE_NOTE_FOCUS = "SAVE_NOTE_FOCUS";

//release notes
export const SAVE_RELEASENOTES_REQUEST = "SAVE_RELEASENOTES_REQUEST";
export const SAVE_RELEASENOTES_SUCCESS = "SAVE_RELEASENOTES_SUCCESS";
export const SAVE_RELEASENOTES_FAILED = "SAVE_RELEASENOTES_FAILED";
//release notes user
export const SAVE_RELEASENOTES_USER_SUCCESS = "SAVE_RELEASENOTES_USER_SUCCESS";
export const SAVE_RELEASENOTES_USER_FAILED = "SAVE_RELEASENOTES_USER_FAILED";
