import React, { useState, useEffect } from "react";
import {
  Dialog,
  Slide,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";

import { subLevelTitles, checkmarkLimits } from "../../utils/global";

import "../../styles/components/TopBar/FullMapAnalysisDialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BorderLinearProgress = withStyles({
  root: {
    height: 22,
    width: 300,
    borderRadius: 10,
    backgroundColor: "rgba(195, 195, 195, 0.6)",
  },
  bar: {
    backgroundColor: "#FFFFFF",
  },
})(LinearProgress);

const CompletedBorderLinearProgress = withStyles({
  root: {
    height: 22,
    width: 300,
    borderRadius: 10,
    backgroundColor: "rgba(195, 195, 195, 0.6)",
  },
  bar: {
    backgroundColor: "#6FCF97",
  },
})(LinearProgress);

export const levelTitles = [
  "Building Capability",
  "Understanding Interdependencies",
  "Discovering Key Insights",
];

const levelDescriptions = [
  "Are you on your way to creating a viable knowledge map?",
  "Is your knowledge map demonstrating a basic understanding of a topic or challenge?",
  "Is your knowledge map structured for deep understanding and discovery of high-leverage opportunities for improving results?",
];
const subLevelHelpers = [
  [
    "Number of variables",
    "Number of connectors",
    "Number of key indicators with measures",
  ],
  [
    "Percentage of variables that are strong points",
    "Percentage of strong points that are free of fog",
    "Number of feedback loops",
  ],
  [
    "Presence of balancing feedback loops",
    "Number of focal points",
    "Number of leverage points",
  ],
];
// const tips = [
//   [
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth).",
//     },
//   ],
//   [
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth).",
//     },
//   ],
//   [
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth). Balancing loops are necessary for sustainable systems.",
//     },
//     {
//       title: "You could use some Balancing Loops",
//       desc: "You have feedback loops in your map but they are all reinforcing! Most systems have balancing feedback loops too (e.g. resources that become depleted with growth).",
//     },
//   ],
// ];

const FullMapAnalysisDialog = ({ open, onClose, levelInfo, levelDetails }) => {
  const [showLevel, setShowLevel] = useState(1);

  useEffect(() => {
    if (open && levelInfo) {
      setShowLevel(levelInfo.level);
    }
  }, [open,levelInfo]);

  const isCompleteDepth = () => {
    if (!levelDetails) return false;

    if (showLevel === 1 && levelDetails[showLevel - 1].depth >= 1) {
      return true;
    }
    if (showLevel === 2 && levelDetails[showLevel - 1].depth >= 1) {
      return true;
    }
    if (showLevel === 3 && levelDetails[showLevel - 1].depth >= 1) {
      return true;
    }

    return false;
  };

  const isCompletedBreadth = () => {
    if (!levelDetails) return false;

    if (showLevel === 1 && levelDetails[showLevel - 1].breath >= 1) {
      return true;
    }
    if (showLevel === 2 && levelDetails[showLevel - 1].breath >= 0.4) {
      return true;
    }
    if (showLevel === 3 && levelDetails[showLevel - 1].breath >= 0.25) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      style={{ zIndex: 1301 }}
      BackdropProps={{ invisible: false }} //disableBackdropClick
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <div className="analysis-container">
        <section className="analysis-header">
          <h1>Map Analysis</h1>
          <div className="analysis-header__levelpan">
            <p>level</p>
            <div className="levels-container">
              <Tooltip title={levelTitles[0]} leaveDelay={200}>
                <button
                  className={`level level-${
                    showLevel === 1 ? "filled" : "outline"
                  }`}
                  onClick={() => setShowLevel(1)}
                >
                  1
                </button>
              </Tooltip>
              <Tooltip title={levelTitles[1]} leaveDelay={200}>
                <button
                  className={`level level-${
                    showLevel === 2 ? "filled" : "outline"
                  }`}
                  onClick={() => setShowLevel(2)}
                >
                  2
                </button>
              </Tooltip>
              <Tooltip title={levelTitles[2]} leaveDelay={200}>
                <button
                  className={`level level-${
                    showLevel === 3 ? "filled" : "outline"
                  }`}
                  onClick={() => setShowLevel(3)}
                >
                  3
                </button>
              </Tooltip>
            </div>
          </div>
          <Tooltip title="Close" leaveDelay={200}>
            <IconButton onClick={onClose} className="btn-close">
              <CloseIcon fontSize={"large"} />
            </IconButton>
          </Tooltip>
        </section>
        <section className="analysis-body">
          <div className="level-processbar-container">
            <h1 className="level-title">
              <span>{`Level ${showLevel}: `}</span>
              {levelTitles[showLevel - 1]}
            </h1>
            <p className="level-desc">{levelDescriptions[showLevel - 1]}</p>
            <div className="progressbar-container">
              <div className="progressbar">
                <span className="progressbar-name">
                  {subLevelTitles[showLevel - 1][0]}
                </span>
                <div>
                  <div
                    className={`checkmark-${checkmarkLimits[showLevel - 1][0]}`}
                  ></div>
                  {isCompletedBreadth() ? (
                    <CompletedBorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].breath : 0)
                      }
                    />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].breath : 0)
                      }
                    />
                  )}
                </div>
                <span className="level-value">
                  {levelDetails ? levelDetails[showLevel - 1].breadthLabel : ""}
                </span>
                {isCompletedBreadth() && (
                  <CheckCircleIcon
                    style={{ color: "#6FCF97", marginLeft: 10 }}
                    fontSize={"small"}
                  />
                )}
              </div>
              <span className="progressbar-helper">
                {subLevelHelpers[showLevel - 1][0]}
              </span>
            </div>
            <div className="progressbar-container">
              <div className="progressbar">
                <span className="progressbar-name">
                  {subLevelTitles[showLevel - 1][1]}
                </span>
                <div>
                  <div
                    className={`checkmark-${checkmarkLimits[showLevel - 1][1]}`}
                  ></div>
                  {isCompleteDepth() ? (
                    <CompletedBorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].depth : 0)
                      }
                    />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].depth : 0)
                      }
                    />
                  )}
                </div>
                <span className="level-value">
                  {levelDetails ? levelDetails[showLevel - 1].depthLabel : ""}
                </span>
                {isCompleteDepth() && (
                  <CheckCircleIcon
                    style={{ color: "#6FCF97", marginLeft: 10 }}
                    fontSize={"small"}
                  />
                )}
              </div>
              <span className="progressbar-helper">
                {subLevelHelpers[showLevel - 1][1]}
              </span>
            </div>
            <div className="progressbar-container">
              <div className="progressbar">
                <span className="progressbar-name">
                  {subLevelTitles[showLevel - 1][2]}
                </span>
                <div>
                  <div
                    className={`checkmark-${checkmarkLimits[showLevel - 1][2]}`}
                  ></div>
                  {levelDetails && levelDetails[showLevel - 1].clarity === 1 ? (
                    <CompletedBorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].clarity : 0)
                      }
                    />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      color="primary"
                      value={
                        100 *
                        (levelDetails ? levelDetails[showLevel - 1].clarity : 0)
                      }
                    />
                  )}
                </div>
                <span className="level-value">
                  {levelDetails ? levelDetails[showLevel - 1].clarityLabel : ""}
                </span>
                {levelDetails && levelDetails[showLevel - 1].clarity === 1 && (
                  <CheckCircleIcon
                    style={{ color: "#6FCF97", marginLeft: 10 }}
                    fontSize={"small"}
                  />
                )}
              </div>
              <span className="progressbar-helper">
                {subLevelHelpers[showLevel - 1][2]}
              </span>
            </div>
          </div>
          <div className="level-tips-container">
            <h1 className="level-tips-title">Tips for improvement</h1>
            <div className="tips-list">
              This feature is in development.
              {/* {tips[showLevel - 1].map((tip, ind) => (
                <div key={ind} className="tip-item">
                  <h4>{tip.title}</h4>
                  <p>{tip.desc}</p>
                </div>
              ))} */}
            </div>
          </div>
        </section>
      </div>
    </Dialog>
  );
};

export default FullMapAnalysisDialog;
