import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { lighten, withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Popover,
  LinearProgress,
  TextField,
  Tooltip,
  // Menu,
  // MenuItem,
  // Divider,
  // ListItemText,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Container,
  Button,
} from "@material-ui/core";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import html2Canvas from "html2canvas";
import {
  deleteMap,
  duplicateMap,
  addPartipant,
  selectMap,
  add_token,
  moveMapToFolder,
  renameMapTitle,
  saveCycles,
  saveCurrentMapID,
  clearCurrentMapData,
  setActivemap,
  uploadMapData,
  startedNewMap,
  setLoopView,
  leaveMap,
  feedbackCounter,
  saveAsATemplate,
} from "../../redux/actions/nodeAction";
import InviteMemberDlg from "../SideBar/InviteMemberDlg";
import { removeFolder } from "../../redux/actions/folderAction";
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from "workerize-loader!../../utils/getScoreByLevel";

import LevelPan from "./LevelPan";
import FullMapAnalysisDialog from "./FullMapAnalysisDialog";
import { clearChatHistory } from "../../redux/actions/chatAction";
import { deleteMouseMovement } from "../../redux/actions/movementAction";
import { levelTitles } from "./FullMapAnalysisDialog";
import ChatButton from "./ChatButton";

import { isDifferentTwoObj } from "../../utils/global";
import "../../styles/components/TopBar/index.scss";
// import ConfirmDialog from "../Canvas/ConfirmDialog";
import VisibilityItem from "../Canvas/VisibilityItem";
import { visibilityItems } from "../../utils/global";
import {
  Rename,
  Invite,
  Duplicate,
  Move,
  Download,
  // Print,
  Delete,
  Template,
  New,
  Share_B,
  Down_arrow,
  Down_arrow_white,
} from "../../assets/menuIcons/index";
import { CrudModal, UpgradePlanModal } from "../modal/index";
import { toast } from "react-toastify";

const BorderLinearProgress = withStyles({
  root: {
    height: 18,
    width: 160,
    borderRadius: 9,
    backgroundColor: lighten("#E6E6E6", 0.5),
    margin: 7,
  },
  bar: {
    background:
      "linear-gradient(180deg, #009FD7 0%, #2DC1F5 44.79%, #009FD7 100%)",
  },
})(LinearProgress);
const instance = worker();

var key_indicators;
var strong_point;
var focal_point;
var leveragePoint;
var fog_count;
var time_dleays;
const TopbarComponent = ({
  openSidebar,
  focusedCycle,
  editableTitle,
  setEnableEditTitle,
  closeEditableText,
  showSidebar,
  showChatbar,
  showFeedbackBar,
  openFeedbackBar,
  closeFeedbackBar,
  status,
  onChangeVisibility,
  // onShowFeedbackBar,
  showNotesBar,
  showHelp,
  openhelp,
  openchat,
  closeChatbar,
  setCloseHelp,
  showVariableText,
  setFocuedCycle,
  closeSidebar,
  isShowTopProgressBar,
  // customCycles,
  // isLengthOfFeedbackLoop,
}) => {
  const [title, setTitle] = useState("New Map");
  const [levelInfo, setLevelInfo] = useState({
    level: 1,
    breath: 0,
    depth: 0,
    clarity: 0,
  });
  const user = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.auth);
  const { folders, selectedTab, get_templates } = useSelector(
    (state) => state.folder
  );
  let newArr = [...folders];
  // if(permission !== "premium"){
  newArr.splice(0, 1);
  // }
  const history = useHistory();
  const [levelDetails, setLevelDetails] = useState(null);
  const [openFullAnalysis, setOpenFullAnalysisDlg] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    cycles,
    selectedMapData,
    createdNewMap,
    isValidEmail,
    maps,
    isHideFully,
    unUsepoint,
    // loopView,
  } = useSelector((state) => state.node);
  const [debouncedMapData] = useDebounce(selectedMapData, 500);
  const [opencenterModal, setOpenCenterModal] = useState("");
  // const [isOpenConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [inviteMembers, setInviteMembers] = useState([""]);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [errText, setErrText] = useState("");
  const [setdrop, setdropvalue] = useState("");
  const [isShowFolders, setShowFolders] = useState(false);
  const [openImageDlg, setOpenImageDlg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState(null);
  const [OpenVisibility, setOpenVisibility] = useState(false);
  const [ChangeOption, setChangeOption] = useState("");
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [templetIndexNumber, setTempletIndexNumber] = useState("");
  const [upgradePlanModalShow, setUpgradePlanModalShow] = useState(false);
  // const [isClickOnNewMap, setIsClickOnNewMap] = useState(false);
  const dispatch = useDispatch();

  const user_subscription = selectedMapData?.tag;
  const Data = [
    {
      title: "Delete map",
      description: `${
        selectedMapData?.creator && selectedMapData.creator.length >= 2
          ? "You are about to permanently delete this map. If you don’t want your collaborators to lose access, you can transfer ownership to the map menu and then leave the map."
          : "Are you sure you want to permanently delete this map?"
      }`,
      first_button: "CANCEL",
      second_button: "DELETE",
    },
    {
      title: "Duplicate map",
      description:
        "You are about to create a copy of this map. The current map’s collaborators will not have access to the duplicated map unless they are invited.",
      first_button: "CANCEL",
      second_button: "DUPLICATE",
    },
    {
      title: "Move this map to a folder",
      description: "",
      first_button: "CANCEL",
      second_button: "MOVE MAP",
    },
  ];

  useEffect(() => {
    if (createdNewMap) {
      handlePopoverClose();
    }
  }, [createdNewMap]);

  useEffect(() => {
    if (debouncedMapData) {
      instance.getScoreInfo(debouncedMapData).then((result) => {
        if (
          !isDifferentTwoObj(levelInfo, result.currentLevel) ||
          !isDifferentTwoObj(levelDetails, result.levelDetails)
        ) {
          setLevelInfo(result.currentLevel);
          setLevelDetails(result.levelDetails);
        }
        instance.getTotalCycles().then((result) => {
          dispatch(saveCycles(result));
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMapData]);

  useEffect(() => {
    var keyIndicators_count =
      selectedMapData?.data &&
      selectedMapData?.data.filter((tag) => {
        return tag.kpi === true;
      }).length;
    var strongPoint_count =
      selectedMapData?.data &&
      selectedMapData?.data.filter((tag) => {
        return tag.comein.length >= 2;
      }).length;
    var leveragePoint_count =
      selectedMapData?.data &&
      selectedMapData?.data.filter((tag) => {
        return tag.allowLeverage === true;
      }).length;
    var focalPoint_count =
      selectedMapData?.data &&
      selectedMapData?.data.filter((tag) => {
        return tag.isLeveragePoint === true;
      }).length;
    var point_fog_count =
      selectedMapData?.data &&
      selectedMapData?.data.filter((tag) => {
        return tag.fog === true;
      }).length;
    var way_fog_count =
      selectedMapData?.ways &&
      selectedMapData.ways.filter((tag) => {
        return tag.fog === true;
      }).length;
    var timeDleays_count =
      selectedMapData?.ways &&
      selectedMapData.ways.filter((tag) => {
        return tag.timeDelay === true;
      }).length;

    key_indicators = keyIndicators_count;
    strong_point = strongPoint_count;
    focal_point = focalPoint_count;
    leveragePoint = leveragePoint_count;
    fog_count = Number(point_fog_count) + Number(way_fog_count);
    time_dleays = timeDleays_count;
  }, [selectedMapData]);
  const handleClickOutside = () => {
    document.getElementById("global-backdrop2").style.display = "none";
    document.getElementById("global-backdrop3").style.display = "none";
    setOpenCenterModal("");
    closeSidebar();
    setShowFolders(false);
  };
  useEffect(() => {
    document
      .getElementById("global-backdrop2")
      .addEventListener("click", handleClickOutside);
    window.addEventListener("popstate", handleClickOutside);
    return () => {
      document
        .getElementById("global-backdrop2")
        .removeEventListener("click", handleClickOutside);
      window.removeEventListener("popstate", handleClickOutside);
    };
  }, [handleClickOutside]);

  const filteredMaps = useMemo(() => {
    /**
     * set limitation per each maps using permission
     */
    let template_map = [];
    maps &&
      maps.map((item, index) => {
        if (item.type === "Template" && permission === "premium") {
          // && item.author == user.email
          template_map.push({ ...item, disabled: false });
        } else if (item.type === "Template" && permission === "basic") {
          template_map.length < 12
            ? template_map.push({ ...item, disabled: false })
            : template_map.push({ ...item, disabled: true });
        } else if (item.type === "Template" && permission === "starter") {
          template_map.length < 3
            ? template_map.push({ ...item, disabled: false })
            : template_map.push({ ...item, disabled: true });
        }
      });
    const trueFirst = template_map.sort(
      (a, b) => Number(a.disabled) - Number(b.disabled)
    );
    let filterRemoveMap = [];
    trueFirst.map((item) => {
      if (item.removeTemplate) {
        const found = item.removeTemplate.some(
          (el) => el.emailId === localStorage.getItem("email")
        );
        if (!found) filterRemoveMap.push(item);
      } else {
        filterRemoveMap.push(item);
      }
    });

    let limitedMaps;
    if (permission === "premium") {
      // limitedMaps = maps && maps.map((map) => ({ ...map, disabled: false }));
      limitedMaps = maps
        ?.filter((item) => item.type !== "Template")
        .map((map) => ({ ...map, disabled: false }));
    } else if (permission === "basic") {
      // limitedMaps =
      //   maps &&
      //   maps.map((map, index) =>
      //     index < 12 ? { ...map, disabled: false } : { ...map, disabled: true }
      //   );
      limitedMaps = maps
        ?.filter((item) => item.type !== "Template")
        .map((map, index) =>
          index < 12 ? { ...map, disabled: false } : { ...map, disabled: true }
        );
    } else {
      // limitedMaps =
      //   maps &&
      //   maps.map((map, index) =>
      //     index < 3 ? { ...map, disabled: false } : { ...map, disabled: true }
      //   );
      limitedMaps = maps
        ?.filter((item) => item.type !== "Template")
        .map((map, index) =>
          index < 3 ? { ...map, disabled: false } : { ...map, disabled: true }
        );
    }

    if (selectedTab === 0 || !newArr) {
      return limitedMaps;
    }
    if (newArr && selectedTab > newArr?.length) {
      return [];
    }
    return limitedMaps?.filter((map) => {
      let folderId = "";
      if (!map.folders) {
        folderId = "";
      } else {
        folderId = map.folders[user.uid];
      }
      return folderId === newArr[selectedTab - 1]?.id;
    });
  }, [selectedTab, maps, permission]);

  const handlePopoverOpen = (event) => {
    document.getElementById("global-backdrop3").style.display = "block";
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openFullAnalysisDlg = () => {
    setOpenFullAnalysisDlg(true);
    handlePopoverClose();
    setOpenCenterModal("");
  };

  const closeFullAnalysisDlg = () => {
    setOpenFullAnalysisDlg(false);
  };

  const openChatbar = () => {
    document.getElementById("global-backdrop3").style.display = "block";
    showChatbar();
    closeFullAnalysisDlg();
    setOpenCenterModal("");
    setOpenVisibility(false);
  };

  const openFeedbackbar = async () => {
    document.getElementById("global-backdrop3").style.display = "block";
    showFeedbackBar();
    closeFullAnalysisDlg();
    setOpenCenterModal("");
    setOpenVisibility(false);
    closeChatbar();
    setCloseHelp();
    closeSidebar();
    setShowFolders(false);
    //for cycle loop counter
    await dispatch(feedbackCounter());
  };

  // const unViewCount = useMemo(() => {
  //   if (selectedMapData?.cycles && user.email && createdNewMap == false) {
  //     return selectedMapData?.cycles?.filter(
  //       (showBy) => !showBy.showdBy || !showBy.showdBy.includes(user.email)
  //     ).length;
  //   }
  //   return 0;
  // }, [selectedMapData?.cycles, isLengthOfFeedbackLoop]);

  const openotesbar = () => {
    showNotesBar();
    closeFullAnalysisDlg();
    setOpenCenterModal("");
  };
  const openHelp = () => {
    document.getElementById("global-backdrop3").style.display = "block";
    showHelp();
    setOpenCenterModal("");
    setOpenVisibility(false);
    closeFullAnalysisDlg();
    closeChatbar();
    closeSidebar();
    setShowFolders(false);
  };
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setOpenCenterModal("");
        setShowFolders(false);
        setOpenModal(false);
      }
    };
    document.addEventListener("keydown", close);
    return () => document.addEventListener("keydown", close);
  }, []);

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      dispatch(renameMapTitle(title !== "" ? title : "Untitled map"));
      closeEditableText();
    }
  };
  const OpenCenterModal = () => {
    document.getElementById("global-backdrop2").style.display = "block";
    document.getElementById("global-backdrop3").style.display = "block";
    setOpenCenterModal(opencenterModal === "" ? "show" : "");
    setCloseHelp(false);
    closeSidebar();
    setOpenVisibility(false);
  };
  const handleShowSidebar = () => {
    document.getElementById("global-backdrop3").style.display = "block";
    showSidebar();
    setOpenCenterModal("");
    setShowFolders(false);
    // closeFeedbackBar();
    // closeChatbar();
    setCloseHelp();
    setOpenVisibility(false);
    closeFullAnalysisDlg();
  };

  const open = Boolean(anchorEl);
  const id = OpenVisibility ? "simple-popover" : undefined;

  const getTotalLevel = () => {
    if (levelInfo.level < 3) {
      return levelInfo.breath + levelInfo.depth + levelInfo.clarity;
    }

    const isCompletedStability = levelInfo.breath > 0.25 ? 1 : 0;
    const isCompletedFocus = levelInfo.depth;
    const isCompletedLeverage = levelInfo.clarity;
    return isCompletedStability + isCompletedFocus + isCompletedLeverage;
  };
  const onDuplicateMap = () => {
    history.push("/home");
    dispatch(duplicateMap(selectedMapData));
  };
  // const handleCloseConfirmModal = () => {
  //   setOpenConfirmDlg(false);
  // };
  // const handlePrintMap = async () => {
  //   await setOpenConfirmDlg(false);
  //   const content = document.querySelector("iframe").contentWindow;
  //   content.focus();
  //   content.print();
  // };
  const onDeleteMap = () => {
    history.push("/home");
    dispatch(deleteMap(selectedMapData));
    if (newArr && newArr[selectedTab - 1]) {
      dispatch(removeFolder(user.uid, newArr[selectedTab - 1].id));
    }
  };
  const onItemSelect = (value) => {
    setChangeOption(value);
  };
  const decreaseInviteMember = (ind) => {
    if (inviteMembers.length !== 1) {
      let remove_Email = inviteMembers;
      remove_Email = remove_Email.filter((_, i) => ind !== i);
      setInviteMembers(remove_Email);
    }
  };
  const increaseInviteMember = () => {
    let newInviteMembers = inviteMembers.slice();
    newInviteMembers.push("");
    setErrText("");
    setInviteMembers(newInviteMembers);
  };
  const handleCloseInviteDlg = () => {
    setInviteMembers([""]);
    setOpenInviteDialog(false);
  };

  const handleChange = (event, ind) => {
    let newInviteMembers = inviteMembers.slice();
    newInviteMembers[ind] = event.target.value;
    setInviteMembers(newInviteMembers);
  };
  const sendInvite = () => {
    dispatch(addPartipant(inviteMembers, setdrop));
  };
  const onDropValue = (value) => {
    setdropvalue(value);
  };
  const handleMoveMapToFolder = (item) => {
    dispatch(moveMapToFolder(selectedMapData.id, user.uid, item.id));
    setOpenCenterModal("");
    setShowFolders(false);
    setOpenModal(false);
  };
  const printMap = (e) => {
    if (user_subscription !== "premium") {
      handleOpenImageDlg();
      return;
    }
    setOpenCenterModal("");
    // setOpenConfirmDlg(true);
    setTempletIndexNumber(4);
    setSaveAsTemplate(true);
  };
  const handleOpenImageDlg = () => {
    setOpenImageDlg(true);
  };
  const handleCloseImageDlg = () => {
    setOpenImageDlg(false);
  };
  const downloadMap = (e) => {
    if (user_subscription !== "premium") {
      handleOpenImageDlg();
      return;
    }

    html2Canvas(
      document
        .querySelector("iframe")
        .contentDocument.body.querySelector("canvas")
    ).then((canvas) => {
      const canvasImage = canvas.toDataURL(`${selectedMapData.name}.png`, 1.0);
      const link = document.createElement("a");
      link.setAttribute("href", canvasImage);
      link.setAttribute("download", `${selectedMapData.name}.png`);
      document.body.appendChild(link);
      link.click();
    });
  };
  const Cancel = () => {
    setShowFolders(false);
    setOpenModal(false);
    setItem(null);
  };
  const handleOpenVisibility = () => {
    document.getElementById("global-backdrop3").style.display = "block";
    setOpenCenterModal("");
    closeChatbar();
    setCloseHelp();
    closeFeedbackBar();
    setShowFolders(false);
    closeFullAnalysisDlg();
    closeSidebar();
    setOpenVisibility(!OpenVisibility);
  };
  const handleCloseVisibility = () => {
    setOpenVisibility(false);
  };
  const handleChangeVisibility = async (type) => {
    setFocuedCycle(null);
    await dispatch(setLoopView(null, isHideFully, unUsepoint));
    if (type === "all") {
      onChangeVisibility(null);
      return;
    }

    let newStatus = null;
    if (!status) {
      newStatus = Object.keys(visibilityItems).filter(
        (item) => item !== type && item !== "all"
      );
    } else if (status && status.includes(type)) {
      newStatus = status.filter((state) => state !== type);
    } else {
      newStatus = [...status, type];
    }

    if (newStatus.length === 6) {
      onChangeVisibility(null);
    } else {
      if (parseInt(newStatus.length) === parseInt(6)) {
        onChangeVisibility(null);
      } else {
        onChangeVisibility(newStatus);
      }
    }
  };
  const handleShowFeedbackLoops = () => {
    openFeedbackbar();
  };
  const onLeaveMap = () => {
    dispatch(leaveMap(selectedMapData));
  };
  const hover = {
    backgroundColor: "#F5F5F5",
  };
  const CustomToastWithLink = (data) => (
    <div style={{ textAlign: "center" }}>
      You've reached your map limit.{" "}
      <a
        href="https://cauzality.com/plans/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Upgrade
      </a>{" "}
      {data === "starter"
        ? "for more maps."
        : "your account for unlimited maps."}
    </div>
  );
  const createNewMap = () => {
    if (permission === "starter" && filteredMaps && filteredMaps.length >= 3) {
      toast(CustomToastWithLink("starter"));
      return;
    }
    if (permission === "basic" && filteredMaps && filteredMaps.length >= 12) {
      toast(CustomToastWithLink("basic"));
      return;
    }
    document.getElementById("global-backdrop2").style.display = "none";
    document.getElementById("global-backdrop3").style.display = "block";
    setOpenCenterModal("");
    dispatch(deleteMouseMovement());
    dispatch(saveCurrentMapID(null));
    dispatch(clearCurrentMapData());
    dispatch(clearChatHistory());
    dispatch(setActivemap(true));
    dispatch(uploadMapData(null));
    dispatch(startedNewMap(true));
    setTimeout(() => {
      dispatch(startedNewMap(false));
    }, 1000);
  };
  const closeAll = () => {
    document.getElementById("global-backdrop2").style.display = "none";
    document.getElementById("global-backdrop3").style.display = "none";
    closeSidebar();
    closeFullAnalysisDlg();
    setOpenCenterModal("");
    setShowFolders(false);
    setOpenVisibility(false);
    closeFeedbackBar();
    closeChatbar();
    setCloseHelp();
  };
  const handleCloseModal = () => {
    setSaveAsTemplate(false);
    Cancel();
  };
  const handleTemp = async () => {
    switch (templetIndexNumber) {
      case 0:
        // dispatch(
        //   moveMapToFolder(
        //     selectedMapData && selectedMapData.id,
        //     user.uid,
        //     get_templates.id,
        //     "Templates"
        //   )
        // );
        dispatch(
          saveAsATemplate(
            selectedMapData && selectedMapData.id,
            user.uid,
            get_templates.id,
            selectedMapData
          )
        );
        handleCloseModal();
        break;
      case 3:
        onDeleteMap();
        break;
      case 4:
        // await setOpenConfirmDlg(false);
        const content = document.querySelector("iframe").contentWindow;
        content.focus();
        content.print();
        break;
      case 5:
        await setSaveAsTemplate(false);
        downloadMap();
        break;
      default:
        break;
    }
  };
  const upgradePlanHandleClose = () => {
    setUpgradePlanModalShow(false);
  };
  return (
    <>
      <div
        id="global-backdrop2"
        className="global-backdrop"
        style={{ display: "none" }}
      ></div>
      <Toolbar className="topbar-container">
        <div
          id="global-backdrop3"
          className="Toolbar-global-backdrop"
          style={{ display: "none" }}
          onClick={() => closeAll()}
        ></div>
        <div className="left-section">
          <div className="left-first" style={{ height: "100%" }}>
            <Tooltip
              title="Map Menu"
              leaveDelay={200}
              disableHoverListener={showVariableText ? false : true}
            >
              <button
                onClick={handleShowSidebar}
                className="btn btn-menu"
                style={{ backgroundColor: openSidebar ? "#384360" : "" }}
              >
                <img className="logo" src="/assets/ico-menu.svg" alt="" />
                <img
                  style={{ width: "13px" }}
                  src={openSidebar ? Down_arrow_white : Down_arrow}
                  alt=""
                />
                {/* <i
                  className="fas fa-1x fa-chevron-down"
                  style={{
                    color: openSidebar ? "#ffffff" : "#000000",
                    fontSize: "15px",
                  }}
                ></i> */}
              </button>
            </Tooltip>
          </div>
          <div className="level-container" onClick={handlePopoverOpen}>
            {isShowTopProgressBar && (
              <>
                <Tooltip
                  title={levelTitles[levelInfo.level - 1]}
                  leaveDelay={200}
                  disableHoverListener={showVariableText ? false : true}
                >
                  <span>Level {levelInfo.level}</span>
                </Tooltip>
                <Tooltip
                  title="Score Summary"
                  leaveDelay={200}
                  disableHoverListener={showVariableText ? false : true}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    color="primary"
                    value={(100 * getTotalLevel()) / 3}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className="center-section">
          {editableTitle ? (
            <TextField
              className="title"
              value={title}
              autoFocus
              onBlur={() => {
                dispatch(renameMapTitle(title !== "" ? title : "Untitled map"));
                closeEditableText();
              }}
              onKeyPress={handleKeyPress}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              onFocus={(e) => e.target.select()}
            />
          ) : (
            <Tooltip
              title="Edit Map Name"
              leaveDelay={200}
              disableHoverListener={showVariableText ? false : true}
            >
              <h1
                className="title"
                style={{ width: "auto" }}
                onClick={() => {
                  setEnableEditTitle();
                  setTitle(selectedMapData.name);
                }}
              >
                {selectedMapData ? selectedMapData.name : ""}
              </h1>
            </Tooltip>
          )}{" "}
          <div className="center_dropdown">
            <span
              className="center-icon"
              // style={{ background: "transprent" }}
              // style={opencenterModal === "show" ? hover : {}}
              onClick={OpenCenterModal}
            >
              <img style={{ width: "13px" }} src={Down_arrow} alt="" />
              {/* <i className="fas fa-chevron-down" ></i> */}
            </span>
            <div className={`center_dropdown-content ${opencenterModal}`}>
              {!isShowFolders && (
                <>
                  {selectedMapData && selectedMapData.type !== "Template" && (
                    <span
                      onClick={() => {
                        createNewMap();
                      }}
                    >
                      <img src={New} /> New map
                    </span>
                  )}
                  <span
                    onClick={() => {
                      setOpenCenterModal("");
                      setEnableEditTitle();
                      setTitle(selectedMapData.name);
                      handleClickOutside();
                    }}
                  >
                    <img src={Rename} />{" "}
                    {selectedMapData && selectedMapData.type !== "Template"
                      ? "Rename"
                      : "Rename template"}
                  </span>
                  <span
                    onClick={() => {
                      setOpenCenterModal("");
                      dispatch(
                        selectMap(
                          selectedMapData.id,
                          null,
                          selectedMapData.author
                        )
                      );
                      setAnchorEl(null);
                      setOpenInviteDialog(true);
                      handleClickOutside();
                      dispatch(
                        add_token(
                          selectedMapData.id,
                          selectedMapData.name,
                          localStorage.getItem("user_id"),
                          setdrop
                        )
                      );
                    }}
                  >
                    <img
                      src={
                        selectedMapData && selectedMapData.type !== "Template"
                          ? Invite
                          : Share_B
                      }
                    />
                    {selectedMapData && selectedMapData.type !== "Template"
                      ? "Invite collaborators"
                      : "Share template"}
                  </span>
                  <span
                    onClick={() => {
                      if (selectedMapData?.creator.length > 1) {
                        setItem(1);
                        setOpenModal(true);
                        setOpenCenterModal("");
                        handleClickOutside();
                      } else {
                        setOpenCenterModal("");
                        onDuplicateMap();
                      }
                    }}
                  >
                    <img src={Duplicate} />
                    Duplicate
                  </span>
                  {selectedMapData && selectedMapData.type !== "Template" && (
                    <span
                      onClick={() => {
                        setShowFolders(true);
                        setOpenModal(true);
                        setOpenCenterModal("");
                        setItem(2);
                      }}
                    >
                      <img src={Move} />
                      Move to folder
                    </span>
                  )}
                  {selectedMapData && selectedMapData.type !== "Template" ? ( //permission == "premium" &&
                    <span>
                      <span
                        style={{ padding: "0px" }}
                        onClick={() => {
                          if (permission === "premium") {
                            setOpenCenterModal("");
                            setTempletIndexNumber(0);
                            setSaveAsTemplate(true);
                          } else {
                            setUpgradePlanModalShow(true);
                            setOpenCenterModal("");
                          }
                        }}
                      >
                        <img src={Template} />
                        Save as
                        {permission !== "premium" ? (
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#333333",
                              paddingLeft: "5px",
                            }}
                          >
                            <div className="pro">
                              template
                              <div className="Template_pro">
                                {`A template is a reusable starting point for a new map. `}
                                <a
                                  target="_blank"
                                  href="https://cauzality.com/plans/"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#000",
                                  }}
                                >
                                  Upgrade to Premium
                                </a>{" "}
                                {`to create and share your own.`}
                              </div>
                            </div>
                          </a>
                        ) : (
                          " template"
                        )}
                      </span>
                      {permission !== "premium" && (
                        <div className="pro">
                          <span className="btn-menu-badge">
                            PRO
                            {/* <div className="Template_pro">
                            {`A template is a reusable starting point for a new map. `}
                            <a
                              target="_blank"
                              href="https://cauzality.com/plans/"
                              style={{
                                textDecoration: "underline",
                                color: "#000",
                              }}
                            >
                              Upgrade to Premium
                            </a>{" "}
                            {`to create and share your own.`}
                          </div> */}
                          </span>
                        </div>
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                  <span
                    onClick={() => {
                      // downloadMap();
                      setTempletIndexNumber(5);
                      setSaveAsTemplate(true);
                      handleClickOutside();
                    }}
                  >
                    <img src={Download} />
                    Download image
                  </span>
                  {/* <span
                    onClick={() => {
                      printMap();
                      handleClickOutside();
                    }}
                  >
                    <img src={Print} />
                    Print
                  </span> */}
                  <span
                    onClick={() => {
                      setOpenCenterModal("");
                      if (user.email === selectedMapData.author) {
                        // setItem(0);
                        // setOpenModal(true);
                        // handleClickOutside();
                        setTempletIndexNumber(3);
                        setSaveAsTemplate(true);
                      } else {
                        onLeaveMap();
                      }
                    }}
                  >
                    <img src={Delete} />
                    {selectedMapData?.author === user.email
                      ? "Delete"
                      : "Leave map"}
                  </span>
                  {/* <Divider /> */}
                </>
              )}
              {/* {isShowFolders && (
                <>
                  <ListItemText
                    primary='Add this map to the folder'
                    style={{ padding: 10, borderBottom: "1px solid grey" }}
                  />
                  {folders &&
                    folders.length > 0 &&
                    folders.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMoveMapToFolder(item)}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                </>
              )} */}
            </div>
          </div>
        </div>
        <div className="right-section">
          <Tooltip
            title="Spotlight"
            leaveDelay={200}
            disableHoverListener={showVariableText ? false : true}
          >
            <button
              className="btn border_left"
              onClick={handleOpenVisibility}
              style={OpenVisibility ? hover : {}}
            >
              {/* {!openFeedbackBar ? ( */}
              <img
                style={{ width: 25 }}
                src={
                  status && status.length > 0
                    ? "/assets/ico-eye-blue.svg"
                    : "/assets/ico-eye.svg"
                }
                alt=""
              />
              {/* ) : (
                <img
                  style={{ width: 21 }}
                  src={
                    focusedCycle && focusedCycle.length > 0
                      ? "/assets/ico-eye-blue.svg"
                      : "/assets/ico-feedback.svg"
                  }
                  alt=''
                />
              )} */}
            </button>
          </Tooltip>
          <Tooltip
            title="Feedback Loop"
            leaveDelay={200}
            disableHoverListener={showVariableText ? false : true}
          >
            <button
              className="btn"
              onClick={openFeedbackbar}
              style={openFeedbackBar ? hover : {}}
            >
              <img
                style={{ width: 21 }}
                src={
                  focusedCycle && focusedCycle.length > 0 //loopView && loopView.length > 0
                    ? "/assets/ico-feedback-blue.svg"
                    : "/assets/ico-feedback.svg"
                }
                alt=""
              />
              {/* {isLengthOfFeedbackLoop == 0 ||
                (unViewCount !== 0 && (
                  <div
                    className={
                      unViewCount == 0 || isLengthOfFeedbackLoop == 0
                        ? ""
                        : "chat-badge"
                    }
                  >
                    {isLengthOfFeedbackLoop !== 0
                      ? unViewCount
                      : isLengthOfFeedbackLoop}
                  </div>
                ))} */}
              {cycles?.length !== 0 && (
                <div className="chat-badge">{cycles?.length}</div>
              )}
            </button>
          </Tooltip>
          {/* <Tooltip title="Groups" leaveDelay={200} onClick={openGroupsbar}>
          <button className="btn">
            <img style={{ width: 21 }} src={"/assets/groups.svg"} alt="" />
          </button>
        </Tooltip> */}
          {/* <Tooltip title="Notes" leaveDelay={200}>
            <button className="btn" onClick={openotesbar}>
              <img style={{ width: 18 }} src={"/assets/notes.svg"} alt="" />
            </button>
          </Tooltip> */}
          <ChatButton
            showChatbar={openChatbar}
            open={openchat}
            showVariableText={showVariableText}
          />
          <Tooltip
            title="Help"
            leaveDelay={200}
            disableHoverListener={showVariableText ? false : true}
          >
            <button
              className="btn"
              onClick={openHelp}
              style={openhelp ? hover : {}}
            >
              <img style={{ width: 21 }} src={"/assets/ico-help.svg"} alt="" />
            </button>
          </Tooltip>
        </div>
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <LevelPan
            openFullAnalysisDlg={openFullAnalysisDlg}
            levelInfo={levelInfo}
          />
        </Popover>
        <FullMapAnalysisDialog
          levelInfo={levelInfo}
          levelDetails={levelDetails}
          open={openFullAnalysis}
          onClose={closeFullAnalysisDlg}
        />
      </Toolbar>

      <Popover
        id={id}
        open={OpenVisibility}
        onClose={handleCloseVisibility}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="visibility-popover"
      >
        <div className="visibility-container">
          {Object.keys(visibilityItems).map((itemKey, ind) => {
            {
              return (
                ind < 5 && (
                  <VisibilityItem
                    name={visibilityItems[itemKey]}
                    key={ind}
                    status={status}
                    count={
                      visibilityItems[itemKey] === "Key Indicators"
                        ? key_indicators
                        : visibilityItems[itemKey] === "Strong Points"
                        ? strong_point
                        : visibilityItems[itemKey] === "Focal Points"
                        ? focal_point
                        : visibilityItems[itemKey] === "Leverage Points"
                        ? leveragePoint
                        : visibilityItems[itemKey] === "Time Delays"
                        ? time_dleays
                        : fog_count
                    }
                    onChangeStatus={() => {
                      handleChangeVisibility(itemKey);
                    }}
                  />
                )
              );
            }
          })}
          <hr
            style={{
              width: "100%",
              borderTop: "0.5px solid rgb(180, 180, 180)",
              backgroundColor: "#B4B4B4",
              opacity: "0.4",
            }}
          />
          <VisibilityItem
            name="Feedback Loops"
            status={status}
            count={cycles && cycles.length}
            onChangeStatus={() => {
              handleShowFeedbackLoops();
            }}
          />
        </div>
      </Popover>
      {/* <ConfirmDialog
        open={isOpenConfirmDlg}
        title="Print Map"
        description="Printed images are based on your current view of the map."
        okButtonLabel="Ok"
        cancelButtonLabel="Cancel"
        onClosed={handleCloseConfirmModal}
        onConfirmed={handlePrintMap}
      /> */}
      <Dialog
        onClose={handleCloseImageDlg}
        aria-labelledby="customized-dialog-title"
        open={openImageDlg}
      >
        <div className="invite-dlg">
          <IconButton className="btn-close" onClick={handleCloseImageDlg}>
            <CloseIcon />
          </IconButton>
          <p style={{ width: 400 }}>
            Download or print a high res image of your map any time from your
            map menu (available now to Premium subscribers with Cauzality Labs)
          </p>
        </div>
      </Dialog>
      {openModal ? (
        <Dialog
          BackdropProps={{ invisible: false }} //disableBackdropClick
          className="green-modal"
          maxWidth="xs"
          open={openModal}
        >
          <DialogTitle className="green-modal-header">
            {Data[item].title}
          </DialogTitle>
          <DialogContent className="green-modal-body">
            {Data[item].description !== "" ? (
              <p>{Data[item].description}</p>
            ) : (
              <div style={{ padding: "15px 0px" }}>
                <Select
                  className="select-drop"
                  value={ChangeOption.name}
                  onChange={onItemSelect}
                  placeholder={"CHOOSE A FOLDER"}
                  options={
                    newArr &&
                    newArr.length > 0 &&
                    newArr.map((data, index) => ({
                      value: data.name,
                      label: data.name,
                      id: data.id,
                    }))
                  }
                />
              </div>
            )}
          </DialogContent>
          <Container className="green-modal-footer">
            <Button
              className="btn-outline"
              variant="outlined"
              onClick={() => Cancel()}
            >
              {Data[item].first_button}
            </Button>
            <Button
              className="btn-green"
              onClick={() =>
                item === 0
                  ? onDeleteMap()
                  : item === 1
                  ? onDuplicateMap()
                  : handleMoveMapToFolder(ChangeOption)
              }
            >
              {Data[item].second_button}
            </Button>
          </Container>
        </Dialog>
      ) : (
        ""
      )}
      <InviteMemberDlg
        onAddMember={increaseInviteMember}
        onClosed={handleCloseInviteDlg}
        onConfirm={sendInvite}
        onDropvalue={onDropValue}
        inviteMembers={inviteMembers}
        open={openInviteDialog}
        errText={errText}
        isValidEmail={isValidEmail}
        onHandleChange={handleChange}
        Maptype={selectedMapData && selectedMapData.type}
        onRemoveMember={decreaseInviteMember}
      />
      {saveAsTemplate && (
        <CrudModal
          index={templetIndexNumber}
          show={saveAsTemplate}
          handleSubmit={() => handleTemp()}
          handleCloseModal={() => handleCloseModal()}
          data={selectedMapData}
        />
      )}
      {upgradePlanModalShow && (
        <UpgradePlanModal
          show={upgradePlanModalShow}
          handleCloseModal={() => upgradePlanHandleClose()}
        />
      )}
    </>
  );
};

export default TopbarComponent;
