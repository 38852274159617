import {
  SAVE_RELEASENOTES_USER_SUCCESS,
  SAVE_RELEASENOTES_USER_FAILED,
} from "../actionTypes";
import { releaseNotes_userRef } from "../../core/firebase";

export const ReleaseNotes_Add_User =
  (user_id, releaseNote_id) => async (dispatch) => {
    try {
      const releaseNotesSnapShot = await releaseNotes_userRef.get();
      var releaseNotes = [];
      releaseNotesSnapShot.docs.forEach((doc) => {
        if (doc.data().user_id === user_id) {
          releaseNotes.push(doc.data());
        }
      });
      if (releaseNotes.length === 0) {
        releaseNotes_userRef
          .add({
            user_id: user_id,
            releaseNote_id: releaseNote_id,
            date: new Date(),
          })
          .then((data) => {
            releaseNotes_userRef.doc(data.id).update({ id: data.id });
          });
      }
      dispatch(
        saveReleaseNote_user_Success({
          user_id: user_id,
          releaseNote_id: releaseNote_id,
        })
      );
    } catch (err) {
      dispatch(saveReleaseNote_user_failed());
    }
  };

export const ReleaseNotes_See_User = (user_id) => async (dispatch) => {
  try {
    const releaseNotesSnapShot = await releaseNotes_userRef.get();
    var releaseNotes = [];
    releaseNotesSnapShot.docs.forEach((doc) => {
      if (doc.data().user_id === user_id) {
        releaseNotes.push(doc.data());
      }
    });
    dispatch(saveReleaseNote_user_Success(...releaseNotes));
  } catch (err) {
    dispatch(saveReleaseNote_user_failed());
  }
};

export const saveReleaseNote_user_Success = (payload) => ({
  type: SAVE_RELEASENOTES_USER_SUCCESS,
  payload,
});

export const saveReleaseNote_user_failed = () => ({
  type: SAVE_RELEASENOTES_USER_FAILED,
});
