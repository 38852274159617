import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import "./style.scss";
const Upgrade_Plan_Modal = ({ show, handleCloseModal }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleCloseModal}
        // maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        className="dailog-box "
      >
        <DialogTitle id="">{"Upgrade your plan"}</DialogTitle>
        <DialogContent className="dailog-plan ">
          <DialogContentText>
            <p className="dailog-plan ">
              {" "}
              <Link
                href="https://cauzality.com/plans/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upgrade your plan
              </Link>{" "}
              in order to save your maps as templates.
            </p>
          </DialogContentText>
        </DialogContent>
        <div className="dailog-btn">
          {/* <Button onClick={() => openLink()} className="green-btn">
            Read more
          </Button> */}
          <Button onClick={() => handleCloseModal()} className="green-btn">
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default Upgrade_Plan_Modal;
