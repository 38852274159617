import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { showAllMsg } from "../../redux/actions/chatAction";

const ChatButton = ({ open, showChatbar, showVariableText }) => {
  const dispatch = useDispatch();
  const { chatHistory } = useSelector((state) => state.chat);
  const { email } = useSelector((state) => state.auth);

  const handleOpenChatBar = () => {
    dispatch(showAllMsg());
    showChatbar();
  };

  const unreadMsgCount = useMemo(() => {
    if (chatHistory && email) {
      return chatHistory.filter(
        (chat) =>
          chat.type === "message" &&
          (!chat.showedBy || !chat.showedBy.includes(email))
      ).length;
    }
    return 0;
  }, [chatHistory, email]);
  const hover = {
    backgroundColor: "#F5F5F5"
  }
  return (
    <Tooltip title="Chat" leaveDelay={200} disableHoverListener={showVariableText?false:true}>
      <button className="btn" onClick={handleOpenChatBar} style={(open)?hover:{}}>
        <img src="/assets/ico-chat.svg" alt="" />
        {unreadMsgCount !== 0 && (
          <div className="chat-badge">{unreadMsgCount}</div>
        )}
      </button>
    </Tooltip>
  );
};

export default ChatButton;
