import React from "react";
import { useDispatch } from "react-redux";

import { logout } from "../redux/actions/authAction";
import "../styles/components/Login.scss";
const data = [
  {
    browserName: "Download Chrome",
    browserDownloadLink: "https://www.google.com/chrome/",
    slash: "/",
  },
  {
    browserName: "Download Edge",
    browserDownloadLink: "https://www.microsoft.com/edge",
    slash: "/",
  },
  {
    browserName: "Download Safari",
    browserDownloadLink: "https://support.apple.com/downloads/safari",
  },
];
const NotSupport = () => (
  <div className="login-container">
    <img src="/assets/logo.svg" alt="" />
    <h1 className="not-support-header">Browser Not Supported</h1>
    <p className="not-support-desc">
      Sorry, we are unable to support this browser at this time. Please use
      Chrome, Edge or Safari.
    </p>
    <div className="download-btns">
      {data.map((item, index) => {
        return (
          <>
            <a
              className="not-support-desc"
              href={item.browserDownloadLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.browserName}
            </a>
            <span className="not-support-desc">{item.slash}</span>
          </>
        );
      })}
    </div>
  </div>
);

export const NotSupportSize = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="login-container">
      <img src="/assets/logo.svg" alt="" />
      <h1 className="not-support-header">Screen Size Not Supported</h1>
      <p className="not-support-desc">
        Sorry, we are unable to support smart phones and tablets at this time.
        Please use a larger screen.
      </p>
      <div className="not-support-desc" onClick={handleLogout}>
        <u>Logout</u>
      </div>
    </div>
  );
};

export default NotSupport;
