export const content = [
  {
    id: 1,
    title: "Template created",
    p: "You can now find a template created from this map under Templates in your <span class='headding-text'>Map Library</span>",
  },
  {
    id: 2,
    title: "You are about to modify a template",
    p: "Any changes you make to this template will be saved.",
    p1: "</br><i><b>Tip:</b> you can also duplicate a template to work on a copy of it without changing the original.</i>",
    updaedp:
      "Any changes you make to this template will be saved for all users who have access to it.",
  },
  {
    id: 3,
    title: "Delete template?",
    p: "Are you sure you want to permanently delete this template?",
    p1: "</br><i><b>Tip:</b> you can also modify a template to make changes for all of its users, or you can duplicate it to keep it in your library and remove user access.</i>",
    updaedp:
      "You are about to permanently delete this template. All users sharing it will no longer be able to access it.",
    btn1: "Delete",
    btn2: "Nevermind",
  },
  {
    id: 4,
    title: "Delete map",
    p: "You are about to permanently delete this map. If you don’t want your collaborators to lose access, you can transfer ownership to the map menu and then leave the map.",
    p1: "Are you sure you want to permanently delete this map?",
    btn1: "Delete",
    btn2: "Cancel",
  },
  {
    id: 5,
    title: "Print Map",
    p: "Printed images are based on your current view of the map.",
    btn1: "Ok",
    btn2: "Cancel",
  },
  {
    id: 6,
    title: "Download Image",
    p: "Downloaded images are based on your current view of the map.",
    btn1: "Ok",
    btn2: "Cancel",
  },
  {
    id: 7,
    title: "Template added",
    p: "You've added a copy of someone's Template to your library. You can now open the template as a new map or modify your copy of it.",
    btn1: "Got it",
    btn2: "Cancel",
  },
];
