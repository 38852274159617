import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import auth from "./reducers/authReducer";
import node from "./reducers/nodeReducer";
import chat from "./reducers/chatReducer";
import folder from "./reducers/folderReducer";
import movement from "./reducers/movementReducer";
import note from "./reducers/noteReducer";
import releaseNotes from "./reducers/releaseNotesReducer";
import releaseNotes_user from "./reducers/releaseNote_userReducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const rootReducer = combineReducers({
  auth,
  node,
  chat,
  folder,
  movement,
  note,
  releaseNotes,
  releaseNotes_user
});

const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
