import { UPDATE_USER_ACTION, SAVE_SELECTED_ACTION_ID } from "../actionTypes";

export default function (state = {}, { type, payload }) {
  switch (type) {
    case UPDATE_USER_ACTION: {
      return {
        ...state,
        users: payload,
      };
    }
    case SAVE_SELECTED_ACTION_ID: {
      return {
        ...state,
        selectedActionId: payload,
      };
    }
    default: {
      return state;
    }
  }
}
