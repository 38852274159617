import {
  SAVE_NOTE_REQUEST,
  SAVE_NOTE_SUCCESS,
  SAVE_NOTE_FAILED,
  SAVE_NOTE_FOCUS,
} from "../actionTypes";
import { mapsRef } from "../../core/firebase";

export const uploadNote = (text) => async (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  const { email } = getState().auth;
  try {
    if (email && text !== "") {
      const noteData = {
        text,
        date: new Date(),
        updatedDate: new Date(),
        email:email
      };

      mapsRef
        .doc(selectedMapId)
        .collection("notes")
        .add(noteData)
        .then(async(addedDoc) => {
          mapsRef
          .doc(selectedMapId)
          .collection("notes")
          .doc(addedDoc.id)
          .update({ saveId: addedDoc.id });

          const note = await mapsRef
          .doc(selectedMapId)
            .collection("notes").get();
            var notes = []
            note.docs.forEach((doc)=>{
              notes.push(doc.data());
            })
          dispatch(saveNoteSuccess(notes))
        });
      }
    } catch (err) {
      dispatch(saveNoteFailed());
    }
};

export const GetNote = (id) => async (dispatch) => {
  if (id) {
    try {
      dispatch(saveNoteRequest());
      const noteSnapShot = await mapsRef
        .doc(id)
        .collection("notes")
        .orderBy("date", "asc")
        .get();

      const notes = [];
      noteSnapShot.docs.forEach((doc) => {
        notes.push(doc.data());
      });
      dispatch(saveNoteSuccess(notes));
    } catch (err) {
      dispatch(saveNoteFailed());
    }
  }
};

export const saveNoteRequest = () => ({
  type: SAVE_NOTE_REQUEST,
});

export const saveNoteSuccess = (payload) => ({
  type: SAVE_NOTE_SUCCESS,
  payload,
});

export const saveNoteFailed = () => ({
  type: SAVE_NOTE_FAILED,
});

export const setnoteFocus = (payload) => ({
  type: SAVE_NOTE_FOCUS,
  payload,
});
export const removeNote = (id) => async (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  if (selectedMapId) {
    try {
      dispatch(saveNoteRequest());
      await mapsRef.doc(selectedMapId).collection("notes").doc(id).delete();
      const note = await mapsRef.doc(selectedMapId).collection("notes").get();
      var notes = []
      note.docs.forEach((doc)=>{
        notes.push(doc.data());
      })
      dispatch(saveNoteSuccess(notes));
    } catch (err) {
      dispatch(saveNoteFailed());
    }
  }
};

export const editNote = (noteId, text) => async (dispatch, getState) => {
  const { selectedMapId } = getState().node;
  if (selectedMapId) {
    try {
      dispatch(saveNoteRequest());
      await mapsRef.doc(selectedMapId).collection("notes").doc(noteId).update({ text: text });
      const note = await mapsRef.doc(selectedMapId).collection("notes").get();
      var notes = []
      note.docs.forEach((doc)=>{
        notes.push(doc.data());
      })
      dispatch(saveNoteSuccess(notes));
    } catch (err) {
      dispatch(saveNoteFailed());
    }
  }
};