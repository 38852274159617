import { useEffect, useState } from "react";

const useNetworkConnection = () => {
  const [isDisconnected, setDisconnected] = useState(false);

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [isDisconnected]);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setDisconnected(false);
          })
          .catch(() => {
            setDisconnected(true);
            clearInterval(webPing);
          });
      }, 10000);
      return () => {
        clearTimeout(webPing);
      };
    }

    return setDisconnected(true);
  };

  return { isDisconnected };
};

export default useNetworkConnection;
