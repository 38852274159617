import React from "react";
import { Button } from "@material-ui/core";

import { visibilityItems } from "../../utils/global";

const VisibilityItem = ({ name, status, onChangeStatus, count }) => {
  const getImageName = () => {
    if (status == null) { //!status || status.length === 0
      return {img:"/assets/ico-eye.svg", key:true};
    }

    let isHidden = false;
    status.forEach((state) => {
      if (visibilityItems[state] === name) {
        isHidden = true;
      }
    });

    if (isHidden) {
      return {img:"/assets/ico-eye-close-gray.png", key:false};
    }

    return {img:"/assets/ico-eye-blue.svg", key:true};
  };

  return (
    <Button className="visibility-item" style={{color:(getImageName().key)?"#3C3C3C":"rgba(60, 60, 60, 0.4)"}} onClick={onChangeStatus}>
      {name !== "Feedback Loops" ? (
        <img src={getImageName().img} alt="" />
      ) : (
        <img src="/assets/ico-feedback.svg" alt="" />
      )}
      {name}
      <span style={{fontSize: "13px", marginLeft:"auto", color:(getImageName().key)?"#3C3C3C":"rgba(60, 60, 60, 0.4)"}}>{count}</span>
    </Button>
  );
};

export default VisibilityItem;
