import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Drawer, IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import MyMessageBubble from "./MyMessageBubble";
import OtherMessageBubble from "./OtherMessageBubble";
import { sendMessage } from "../../redux/actions/chatAction";
import { removeFocus } from "../../utils/global";
import "../../styles/components/ChatBar/index.scss";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const ChatbarComponent = ({ open, onClose, dropValue }) => {
  const [message, setMessage] = useState("");
  const [messageHistory, setMessageHistory] = useState(null);

  const { chatHistory } = useSelector((state) => state.chat);
  const { displayName } = useSelector((state) => state.auth);
  const { createdNewMap } = useSelector((state) => state.node);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatHistory) {
      var chats = chatHistory.reverse();
      const msgHistory = chats.filter((chat) => chat.type === "message");
      setMessageHistory(msgHistory);
    } else {
      setMessageHistory([]);
    }
  }, [chatHistory]);

  useEffect(() => {
    if (open) {
      removeFocus();
    }
  }, [open]);

  useEffect(() => {
    if (createdNewMap) {
      onClose();
    }
  }, [createdNewMap, onClose]);

  const onSendMessage = () => {
    dispatch(sendMessage(message));
    setMessage("");
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const isMyMessage = (msg) => {
    if (displayName === msg.username[0]) {
      return true;
    }

    return false;
  };

  const isShowTimestamp = (index) => {
    if (index === 0) return true;

    const deltaTime =
      messageHistory[index].date.seconds -
      messageHistory[index - 1].date.seconds;

    if (deltaTime > 15 * 60) return true;

    return false;
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className="chatbar-container">
        <section className="chatbar-title">
          <h2 className="title title-sidebar">Chat</h2>
          <IconButton onClick={onClose}>
            <CloseIcon className="btn-close" />
          </IconButton>
        </section>
        <section id={"el"} className="messagelist-container">
          {messageHistory &&
            messageHistory.map((msg, ind) => {
              if (isMyMessage(msg)) {
                return (
                  <MyMessageBubble
                    key={ind}
                    message={msg}
                    showTime={isShowTimestamp(ind)}
                  />
                );
              } else {
                return (
                  <OtherMessageBubble
                    key={ind}
                    message={msg}
                    showTime={isShowTimestamp(ind)}
                  />
                );
              }
            })}
          <AlwaysScrollToBottom />
        </section>
        {
          (dropValue === "Observer" || sessionStorage.getItem('guestLogin'))?
            ""
          :
            <section className="messagelist-input">
              <TextField
                className="chat-input"
                multiline
                rows={3}
                value={message}
                placeholder="Type a message..."
                onChange={handleChange}
                onKeyUp={(event) => {
                  if (event.key === "Enter") onSendMessage();
                }}
              ></TextField>
              <Button className="btn-sendmsg" onClick={onSendMessage}>
                <img src="/assets/ico-sendbtn.svg" alt="" />
              </Button>
            </section>
        }
      </div>
    </Drawer>
  );
};

export default ChatbarComponent;
