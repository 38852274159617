import React, { useEffect } from "react";
import { useSelector } from "react-redux"; //useDispatch
import { Tooltip } from "@material-ui/core"; //Popover,

// import VisibilityItem from "./VisibilityItem";
// import { visibilityItems } from "../../utils/global";
// import { setLoopView } from "../../redux/actions/nodeAction";

import "../../styles/components/Canvas/ZoomToolbar.scss";

const ZoomToolbar = ({
  gotoCenter,
  zoomLevel,
  setZoomLevel,
  showVariableText,
  // status,
  // onChangeVisibility,
  // onShowFeedbackBar,
}) => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const { loopArea } = useSelector((state) => state.node);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (loopArea) {
      let preZoomLevel = 0.2;
      for (let i = 0.2; i <= 1; i += 0.1) {
        if (
          window.innerWidth / i > loopArea?.areaSize.x + 200 * i &&
          window.innerHeight / i > loopArea?.areaSize.y + 200 * i
        ) {
          preZoomLevel = i;
        }
      }
      setZoomLevel(Math.round(preZoomLevel * 100));
    }
  }, [loopArea]);

  useEffect(() => {
    setZoomLevel(100);
  }, []);

  // useEffect(() => {
  //   if (createdNewMap) {
  //     // handleCloseVisibility();
  //     onChangeVisibility(null);
  //   }
  // }, [createdNewMap]);

  const handleZoomOut = () => {
    if (zoomLevel > 10) {
      const newZoomLevel = zoomLevel - 5;
      setZoomLevel(newZoomLevel, "button");
    }
  };

  const handleZoomIn = () => {
    if (zoomLevel < 100) {
      const newZoomLevel = zoomLevel + 5;
      setZoomLevel(newZoomLevel, "button");
    }
  };

  // const handleCloseVisibility = () => {
  //   setAnchorEl(null);
  // };

  // const handleChangeVisibility = (type) => {
  //   dispatch(setLoopView(null));
  //   if (type === "all") {
  //     onChangeVisibility(null);
  //     return;
  //   }

  //   let newStatus = null;
  //   if (!status) {
  //     newStatus = Object.keys(visibilityItems).filter(
  //       (item) => item !== type && item !== "all"
  //     );
  //   } else if (status && status.includes(type)) {
  //     newStatus = status.filter((state) => state !== type);
  //   } else {
  //     newStatus = [...status, type];
  //   }

  //   if (newStatus.length === 5) {
  //     onChangeVisibility(null);
  //   } else {
  //     onChangeVisibility(newStatus);
  //   }
  // };

  // const handleShowFeedbackLoops = () => {
  //   onShowFeedbackBar();
  // };
  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <div className="zoom-toolbar__container">
      <button
        className="btn btn-center feedback-item-button"
        onClick={gotoCenter}
      >
        <Tooltip
          title="Center Map"
          leaveDelay={200}
          disableHoverListener={showVariableText ? false : true}
        >
          <img src="/assets/ico-center.svg" alt="center" />
        </Tooltip>
      </button>
      <button className="btn btn-icon" onClick={handleZoomOut}>
        <Tooltip
          title="Zoom Out"
          leaveDelay={200}
          disableHoverListener={showVariableText ? false : true}
        >
          <img src="/assets/img-minus.svg" alt="center" />
        </Tooltip>
      </button>
      <span className="zoom-label">{zoomLevel}%</span>
      <button className="btn btn-icon" onClick={handleZoomIn}>
        <Tooltip
          title="Zoom In"
          leaveDelay={200}
          disableHoverListener={showVariableText ? false : true}
        >
          <img src="/assets/img-plus.svg" alt="center" />
        </Tooltip>
      </button>
    </div>
  );
};

export default ZoomToolbar;
