import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DragM from "dragm";
import { uploadNote } from "../../redux/actions/noteAction";
import EditNote from "./EditnoteContainer";
import "../../styles/components/Canvas/NotesContainer.scss";

const NotesContainer = ({ open, onclose }) => {
  const { notefocus } = useSelector((state) => state.note);
  const [openInpute, setOpenInput] = useState(false);
  const [value, setInpute] = useState("");
  const dispatch = useDispatch();

  const handleNote = (data) => {
    setInpute(data);
  };
  const handleClick = () => {
    setOpenInput(!openInpute);
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      dispatch(uploadNote(value));
      onclose();
      setInpute("");
      setOpenInput(false);
    }
  };
  const handleDelete = () => {
    onclose();
  };
  return (
    <>
      <DragM>
        <div className="note" style={{ display: open ? "block" : "none" }}>
          <div className="note-header">
            <img
              alt="noteEdit"
              src={"/assets/note-edit.svg"}
              onClick={() => handleClick()}
            />
            <img
              alt="noteDelete"
              src={"/assets/note-delete.svg"}
              onClick={() => handleDelete()}
            />
          </div>
          <div className="note-container">
            <input
              type="text"
              placeholder="Enter your note"
              value={value}
              onChange={(e) => {
                handleNote(e.target.value);
              }}
              onKeyPress={handleEnter}
              ref={(input) => input && input.focus()}
            />
          </div>
        </div>
      </DragM>
      {notefocus &&
        notefocus?.map((item, index) =>  <EditNote data={item} key={index} />
        )}
    </>
  );
};
export default NotesContainer;
