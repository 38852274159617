import {
  SAVE_NOTE_REQUEST,
  SAVE_NOTE_SUCCESS,
  SAVE_NOTE_FAILED,
  SAVE_NOTE_FOCUS
} from "../actionTypes";

const initialState = {
  selectedTab: 0,
  note:"",
  default_templates: "",
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_NOTE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SAVE_NOTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        noteHistory: payload,
      };
    }
    case SAVE_NOTE_FAILED: {
      return {
        ...state,
        isLoading: false,
        note: null,
      };
    }
    case SAVE_NOTE_FOCUS: {
      return {
        ...state,
        isLoading: false,
        notefocus: payload,
      };
    }
    default: {
      return state;
    }
  }
}
