import {
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILED,
  SAVE_FOLDERS_FAILED,
  SAVE_FOLDERS_REQUEST,
  SAVE_FOLDERS_SUCCESS,
  SAVE_SELECTED_TAB,
} from "../actionTypes";

const initialState = {
  selectedTab: 0,
  folders: "",
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_FOLDERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SAVE_FOLDERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        folders: payload,
      };
    }
    case SAVE_FOLDERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        folders: null,
      };
    }
    case GET_TEMPLATE_SUCCESS: {
      var arr = [payload, ...state.folders];
      return {
        ...state,
        isLoading: false,
        get_templates: payload,
        folders: arr.filter(
          (v, i, a) => a?.findIndex((t) => t.id === v.id) === i
        ),
      };
    }
    case GET_TEMPLATE_FAILED: {
      return {
        ...state,
        isLoading: false,
        get_templates: null,
      };
    }
    case SAVE_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: payload,
      };
    }
    default: {
      return state;
    }
  }
}
