import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

const stagingConfig = {
  apiKey: "AIzaSyBZ0683zqnG38-LbMudc7idZzwsW--Bihc",
  authDomain: "cauzality-staging.firebaseapp.com",
  databaseURL: "https://cauzality-staging.firebaseio.com",
  projectId: "cauzality-staging",
  storageBucket: "cauzality-staging.appspot.com",
  messagingSenderId: "1000381093889",
  appId: "1:1000381093889:web:cc6e7964ad1026ba5e8800",
  measurementId: "G-KMPY68ZPC8",
};

const config = {
  apiKey: "AIzaSyAwcStrivFHGbVJEzmi4ooODWc4MKZEb94",
  authDomain: "cauzality-beta.firebaseapp.com",
  databaseURL: "https://cauzality-beta.firebaseio.com",
  projectId: "cauzality-beta",
  storageBucket: "cauzality-beta.appspot.com",
  messagingSenderId: "673728857910",
  appId: "1:673728857910:web:efe8120e7e45d10037d597",
  measurementId: "G-ZWKSYMZM00",
};

firebase.initializeApp(
  process.env.REACT_APP_PUBLIC_TYPE === "STAGING" ? stagingConfig : config
);
export const databaseRef = firebase.firestore();

export const authRef = firebase.auth();
export const mapsRef = databaseRef.collection("maps");
export const folderRef = databaseRef.collection("folder");
export const templateRef = databaseRef.collection("templates");
export const releaseNotesRef = databaseRef.collection("releaseNotes");
export const releaseNotes_userRef = databaseRef.collection("releaseNotes_user");
export const userActionsRef = databaseRef.collection("userActions");
export const usersRef = databaseRef.collection("users");
export const orgRef = databaseRef.collection("org");
export const storageRef = firebase.storage().ref();
export const tokenRef = databaseRef.collection("token");
export const avatarColorRef = databaseRef.collection("avatarColor");
