import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Inverted } from "../../assets/other/index";

import "./style.scss";

const ErrorModal = ({ open, hide }) => {
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={() => onClose()}
        // maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        className="dailog-box"
      >
        <DialogTitle id="Inverted">
          <img src={Inverted} style={{ left: "45%", position: "absolute" }} />
        </DialogTitle>

        <DialogContent className="dailog">
          <h1 className="dailog-h1">Uh-oh, something happened!</h1>
          <p style={{ textAlign: "center" }}>
            Map canvas not supported by your graphics processor. Check the
            status at about://gpu
          </p>
        </DialogContent>
        <div className="dailog-btn">
          <Button
            className="green-btn"
            onClick={() => history.push("/home")}
            // onClick={() => hide()}
          >
            Ok, thanks
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default ErrorModal;
