import React from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import { LinearProgress, Button, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { subLevelTitles, checkmarkLimits } from "../../utils/global";

import "../../styles/components/TopBar/LevelPan.scss";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: 133,
    borderRadius: 5,
    backgroundColor: lighten("#E6E6E6", 0.5),
  },
  bar: {
    backgroundColor: "#009FD7",
  },
})(LinearProgress);

const LevelPan = ({ levelInfo, openFullAnalysisDlg }) => {
  const isCompleteDepth = () => {
    if (levelInfo.level === 1 && levelInfo.depth >= 1) {
      return true;
    }
    if (levelInfo.level === 2 && levelInfo.depth >= 1) {
      return true;
    }
    if (levelInfo.level === 3 && levelInfo.depth >= 1) {
      return true;
    }

    return false;
  };

  const isCompletedBreadth = () => {
    if (levelInfo.level === 1 && levelInfo.breath >= 1) {
      return true;
    }
    if (levelInfo.level === 2 && levelInfo.breath >= 0.4) {
      return true;
    }
    if (levelInfo.level === 3 && levelInfo.breath >= 0.25) {
      return true;
    }

    return false;
  };

  return (
    <div className='levelpan-container'>
      <div className='level-controller'>
        {/* <Tooltip
          title={subLevelTitles[levelInfo.level - 1][0]}
          leaveDelay={200}
        > */}
        <span>{subLevelTitles[levelInfo.level - 1][0]}</span>
        {/* </Tooltip> */}

        {/* <Tooltip title={levelInfo.breadthLabel} leaveDelay={200}> */}
        <BorderLinearProgress
          variant='determinate'
          color='primary'
          value={100 * levelInfo.breath}
        />
        {/* </Tooltip> */}
        <div
          className={`checkmark-${
            checkmarkLimits[levelInfo.level - 1][0]
          }`}></div>
        {isCompletedBreadth() && (
          <CheckCircleIcon
            style={{ color: "#6FCF97", marginLeft: 10 }}
            fontSize={"small"}
          />
        )}
      </div>
      <div className='level-controller'>
        {/* <Tooltip
          title={subLevelTitles[levelInfo.level - 1][1]}
          leaveDelay={200}> */}
        <span>{subLevelTitles[levelInfo.level - 1][1]}</span>
        {/* </Tooltip> */}
        {/* <Tooltip title={levelInfo.depthLabel} leaveDelay={200}> */}
        <BorderLinearProgress
          variant='determinate'
          color='secondary'
          value={100 * levelInfo.depth}
        />
        {/* </Tooltip> */}
        <div
          className={`checkmark-${
            checkmarkLimits[levelInfo.level - 1][1]
          }`}></div>
        {isCompleteDepth() && (
          <CheckCircleIcon
            style={{ color: "#6FCF97", marginLeft: 10 }}
            fontSize={"small"}
          />
        )}
      </div>
      <div className='level-controller'>
        {/* <Tooltip
          title={subLevelTitles[levelInfo.level - 1][2]}
          leaveDelay={200}> */}
        <span>{subLevelTitles[levelInfo.level - 1][2]}</span>
        {/* </Tooltip> */}
        <Tooltip title={levelInfo.clarityLabel} leaveDelay={200}>
          <BorderLinearProgress
            variant='determinate'
            color='secondary'
            value={100 * levelInfo.clarity}
          />
        </Tooltip>
        <div
          className={`checkmark-${
            checkmarkLimits[levelInfo.level - 1][2]
          }`}></div>
        {levelInfo.clarity === 1 && (
          <CheckCircleIcon
            style={{ color: "#6FCF97", marginLeft: 10 }}
            fontSize={"small"}
          />
        )}
      </div>
      <Button onClick={openFullAnalysisDlg} className='level-container-title'>
        <h4>Show Full Map Analysis</h4>
      </Button>
    </div>
  );
};

export default LevelPan;
