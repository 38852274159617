export const getAvatarChar = (name, propsName) => {
  if (!name) return "?";
  const useFirstName = !propsName; //localStorage.getItem(USE_FIRST_NAME);

  var nameArr = name.split(" ");
  var array_filter = nameArr.filter((a) => a);

  if (array_filter.length > 1) {
    if (useFirstName && useFirstName === true) {
      return array_filter[0][0].toUpperCase();
    }
    return (array_filter[0][0] + array_filter[1][0]).toUpperCase();
  }
  if (array_filter.length === 1 && array_filter[0]) {
    return array_filter[0][0].toUpperCase();
  }
  return "";
};

export const getAvatarColor = (username) => {
  if (!username) return "grey";
  if (username.endsWith("(Guest)")) {
    username = username.substring(0, username.length - 8);
  }
  switch (username.length % 5) {
    case 0: {
      return "grey";
    }
    case 1: {
      return "purple";
    }
    case 2: {
      return "yellow";
    }
    case 3: {
      return "orange";
    }
    case 4: {
      return "blue";
    }
    default: {
      break;
    }
  }
};

export const VISIBILITY_HIDDEN = "VISIBILITY_HIDDEN";
export const VISIBILITY_SHOW = "VISIBILITY_SHOW";
export const VISIBILITY_NO_DATA = "VISIBILITY_NO_DATA";
export const USE_FIRST_NAME = "USE_FIRST_NAME";
export const COLOR_BIND_FRIENDLY = "COLOR_BIND_FRIENDLY";

export const visibilityItems = {
  key_indicators: "Key Indicators",
  strong_point: "Strong Points",
  focal_points: "Focal Points",
  leverage_points: "Leverage Points",
  fog: "Fog",
  time_delays: "Time Delays"
};

export const subLevelTitles = [
  ["Breadth", "Connectivity", "Measurability"],
  ["Depth", "Clarity", "Feedback"],
  ["Stability", "Focus", "Leverage"],
];

const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const isDifferentTwoObj = (obj1, obj2) => {
  if ((!obj1 && obj2) || (obj1 && !obj2)) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const areArrays = Array.isArray(val1) && Array.isArray(val2);

    if (
      (areObjects && !isDifferentTwoObj(val1, val2)) ||
      (!areObjects && val1 !== val2) ||
      (areArrays && JSON.stringify(val1) !== JSON.stringify(val2))
    ) {
      return false;
    }
  }

  return true;
};

export const checkmarkLimits = [
  [100, 100, 100],
  [50, 100, 100],
  [25, 100, 100],
];

export const removeFocus = () => {
  setTimeout(() => {
    const dlgs = document.getElementsByClassName("MuiDialog-container");
    Array.prototype.forEach.call(dlgs, (el) => {
      el.removeAttribute("tabindex");
    });

    const popups = document.getElementsByClassName("MuiPaper-root");
    Array.prototype.forEach.call(popups, (popup) => {
      popup.removeAttribute("tabindex");
    });
  }, 1000);
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const extractContent = (s, space) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
