import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from "./App";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./styles/index.scss";
import { LinearProgress } from "@material-ui/core";
import { Box } from "@mui/material";

const history = createBrowserHistory();
Sentry.init({
  dsn: `${process.env.REACT_APP_PUBLIC_SENTRY_KEY}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.0, //1.0,
  enableInExpoDevelopment: true,
  debug: true,
  // ignoreErrors: ["ResizeObserver loop limit exceeded"],
  userName: localStorage.getItem("email"),
});
Sentry.configureScope((scope) => {
  scope.setUser({ email: localStorage.getItem("email") });
});
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense
        fallback={
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        }
      >
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
