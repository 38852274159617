import {
  SAVE_FOLDERS_REQUEST,
  SAVE_FOLDERS_SUCCESS,
  SAVE_FOLDERS_FAILED,
  SAVE_SELECTED_TAB,
  GET_TEMPLATE_FAILED,
  GET_TEMPLATE_SUCCESS,
} from "../actionTypes";
import {
  folderRef,
  templateRef,
  // template_exampleRef,
} from "../../core/firebase";

export const uploadFolders = (id, folderName) => async (dispatch, getState) => {
  if (id && folderName) {
    try {
      dispatch(saveFolderRequest());
      const addedDoc = await folderRef
        .doc(id)
        .collection("folders")
        .add({ name: folderName });
      await addedDoc.update({ id: addedDoc.id });
      await dispatch(saveFolders(id));
      dispatch(getTemplate());
    } catch (err) {
      dispatch(saveFolderFailed());
    }
  }
};

export const saveFolders = (id) => async (dispatch) => {
  if (id) {
    try {
      dispatch(saveFolderRequest());
      const foldersSnapShot = await folderRef
        .doc(id)
        .collection("folders")
        .orderBy("name", "asc")
        .get();
      const folders = [];
      foldersSnapShot.docs.forEach((doc) => {
        folders.push(doc.data());
      });
      dispatch(saveFolderSuccess(folders));
      dispatch(getTemplate());
      // const templateSnapShot = await templateRef.orderBy("name", "asc").get();
      // templateSnapShot.docs.forEach((doc) => {
      // dispatch(saveFolderSuccess([doc.data(), ...folders]));
      // });
    } catch (err) {
      dispatch(saveFolderFailed());
    }
  }
};

// Set templates from firebase
export const getTemplate = () => async (dispatch) => {
  try {
    const templateSnapShot = await templateRef.get();
    const templates = [];
    templateSnapShot.docs.forEach((doc) => {
      templates.push(doc.data());
    });
    if (templates.length === 0) {
      templateRef
        .add({
          name: "Templates",
        })
        .then((data) => {
          dispatch(GetTemplateSuccess({ name: "Templates", id: data.id }));
          templateRef.doc(data.id).update({ id: data.id });
        });
    } else {
      dispatch(GetTemplateSuccess(...templates));
    }
  } catch (err) {
    dispatch(GetTemplateFailed());
  }
};

export const saveFolderRequest = () => ({
  type: SAVE_FOLDERS_REQUEST,
});

export const saveFolderSuccess = (payload) => ({
  type: SAVE_FOLDERS_SUCCESS,
  payload,
});

export const saveFolderFailed = () => ({
  type: SAVE_FOLDERS_FAILED,
});

export const removeFolder = (id, folderId) => async (dispatch) => {
  if (id && folderId) {
    try {
      dispatch(saveFolderRequest());
      await folderRef.doc(id).collection("folders").doc(folderId).delete();
      dispatch(saveFolders(id));
      dispatch(getTemplate());
    } catch (err) {
      dispatch(saveFolderFailed());
    }
  }
};

export const renameFolder = (id, folderId, folderName) => async (dispatch) => {
  if (id && folderId) {
    try {
      dispatch(saveFolderRequest());
      await folderRef
        .doc(id)
        .collection("folders")
        .doc(folderId)
        .update({ name: folderName });
      dispatch(saveFolders(id));
      dispatch(getTemplate());
    } catch (err) {
      dispatch(saveFolderFailed());
    }
  }
};

export const saveSelectedTabIndex = (id) => ({
  type: SAVE_SELECTED_TAB,
  payload: id,
});

// default templates for all users
export const GetTemplateSuccess = (payload) => ({
  type: GET_TEMPLATE_SUCCESS,
  payload,
});

export const GetTemplateFailed = () => ({
  type: GET_TEMPLATE_FAILED,
});
