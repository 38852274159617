import {
  SAVE_CHAT_HISTORY_REQUEST,
  SAVE_CHAT_HISTORY_SUCCESSED,
  SAVE_CHAT_HISTORY_FAILED,
  CLEAR_CHAT_HISTORY,
} from "../actionTypes";

const initialState = {};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_CHAT_HISTORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        chatHistory: null,
      };
    }
    case SAVE_CHAT_HISTORY_SUCCESSED: {
      return {
        ...state,
        isLoading: false,
        chatHistory: payload,
      };
    }
    case SAVE_CHAT_HISTORY_FAILED: {
      return {
        ...state,
        isLoading: false,
        chatHistory: null,
      };
    }
    case CLEAR_CHAT_HISTORY: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
