import {
  SAVE_RELEASENOTES_REQUEST,
  SAVE_RELEASENOTES_SUCCESS,
  SAVE_RELEASENOTES_FAILED
} from "../actionTypes";

const initialState = {
  releaseNotes:""
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_RELEASENOTES_REQUEST: {
      return {
        isLoading: true,
      };
    }
    case SAVE_RELEASENOTES_SUCCESS: {
      return {
        ...state,
        releaseNotes: payload,
      };
    }
    case SAVE_RELEASENOTES_FAILED: {
      return {
        releaseNotes: null,
      };
    }
    default: {
      return state;
    }
  }
}
