import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminDashboard from "../container/AdminDashboard";
import { CircularProgress } from "@material-ui/core";

const AuthenticatedRoute = ({ path, component }) => {
  const auth = useSelector((state) => state.auth);
  if (auth?.email) {
    if (auth?.isAdmin) {
      return <Route path="/admin" component={AdminDashboard} />;
    }
    return <Route path={path} component={component} />;
  }

  if (!auth?.isApiCallFinish && localStorage.getItem("user") && auth?.isAdmin) {
    return <></>;
  }
  return auth?.isLoading ? (
    <CircularProgress />
  ) : localStorage.getItem("user_id") ? (
    <Redirect to={path} />
  ) : (
    <Redirect to="/login" />
  );
};

export default AuthenticatedRoute;
