import {
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESSED,
  AUTH_LOGOUT,
  AUTH_SIGN_UP_REQUEST,
  AUTH_SIGN_UP_FAILED,
  AUTH_SIGN_UP_REQUEST_SUCCESS,
  AUTH_ADD_MEMBER_REQUEST,
  AUTH_ADD_MEMBER_SUCCESS,
  AUTH_ADD_MEMBER_FAILED,
  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_SUCCESS,
  COMPLETE_SIGNUP_FAILED,
  SET_ADMIN_MODE,
  SAVE_USER_SUBSCRIPTION,
  UPLOAD_AVATAR_COLOR_SUCCESSED,
  UPLOAD_AVATAR_COLOR_FAILED,
} from "../actionTypes";
import { authRef, avatarColorRef, orgRef, usersRef } from "../../core/firebase";
import { sendEmailTo, updateUserInfo } from "../../services";
import {
  saveCurrentMapID,
  clearCurrentMapData,
  setActivemap,
  clearMaps,
  addPartipantAfterLogin,
} from "./nodeAction";

export const DEFAULT_EMAIL = "boollucas1@gmail.com";
export const DEFAULT_PASSWORD = "Cauza!ityPassword11";

export const handleLogin =
  ({ email = "", password = "" }, dropVaule) =>
  async (dispatch) => {
    await dispatch(loginRequest());

    let newEmail = email;
    let newPassword = password;
    if (email === "") {
      // TODO: Should be replace with TOKEN.
      if (
        localStorage.getItem("email") &&
        localStorage.getItem("email") !== "" &&
        localStorage.getItem("email") !== "boollucas1@gmail.com"
      ) {
        newEmail = localStorage.getItem("email");
        newPassword = localStorage.getItem("password");
      }
    }

    try {
      let user;
      if (newPassword === "Test@123") {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: newEmail }),
        };
        const result = await fetch("/api/getUserByEmail", requestOptions);
        const jsonData = await result.json();
        user = {
          user: jsonData.data,
        };
        await dispatch(
          loginSuccess({
            displayName: user.user.displayName
              ? user.user.displayName
              : user?.user.email?.split("@")[0],
            email: user.user.email,
            photoURL: null,
            uid: user?.user?.uid,
          })
        );
      } else {
        user = await authRef.signInWithEmailAndPassword(newEmail, newPassword);
        await dispatch(loginSuccess(user));
      }
      if (user) {
        await dispatch(getUsreAvatarColor(user.user.uid));
        if (dropVaule) {
          await dispatch(
            addPartipantAfterLogin(newEmail, user.user.displayName, dropVaule)
          );
        }
        localStorage.setItem("email", newEmail);
        // localStorage.setItem("password", newPassword);
        localStorage.setItem("user_id", user.user.uid);
        // localStorage.setItem("token", user.user.ya);
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: newEmail,
            photoURL: user.user.photoURL ? user.user.photoURL : null,
            displayName: user.user.displayName
              ? user.user.displayName
              : user?.user.email?.split("@")[0],
          })
        );
      }
    } catch (err) {
      await dispatch(loginFailed(err.code));
    }
  };

export const loginRequest = () => ({
  type: AUTH_LOGIN_REQUEST,
});

export const loginSuccess = (user) => async (dispatch, getState) => {
  await dispatch(loginRequest());
  const currentEmail = getState().auth.email;
  if (currentEmail && currentEmail === process.env.REACT_APP_ADMIN_ACCOUNT) {
    return;
  }
  dispatch({
    type: AUTH_LOGIN_SUCCESSED,
    payload: user,
  });
  if (user.email === process.env.REACT_APP_ADMIN_ACCOUNT) {
    dispatch(setAdminMode(true));
  }
};

export const sendPasswordResetEmail = () => (dispatch, getState) => {
  const { email } = getState().auth;
  if (email) {
    authRef.sendPasswordResetEmail(email).then(() => {
      dispatch(logout());
      dispatch(saveCurrentMapID(null));
      dispatch(clearCurrentMapData());
      dispatch(setActivemap(false));
      localStorage.clear();
    });
  }
};

export const loginFailed = (errCode) => ({
  type: AUTH_LOGIN_FAILED,
  payload: errCode,
});

export const handleSignup =
  ({ email, firstName, lastName, organization }) =>
  async (dispatch) => {
    dispatch(signupRequest());
    try {
      await sendEmailTo({
        to: process.env.REACT_APP_ADMIN_ACCOUNT,
        from: process.env.REACT_APP_SENDER_ACCOUNT,
        subject: "Onboarding Request Email",
        html: `
        Dear Account Manager,<br />
        Someone want to create his/her account.<br />
        Email address: ${email}<br />
        First name: ${firstName}<br />
        Last name: ${lastName}<br />
        Organization: ${organization}<br />
        <br />
        Looking forward to hearing from you soon.<br />
        Regards
      `,
      });
      dispatch(signupSuccess());
    } catch (err) {
      dispatch(signupFailed());
    }
  };

const signupRequest = () => ({
  type: AUTH_SIGN_UP_REQUEST,
});

const signupSuccess = () => ({
  type: AUTH_SIGN_UP_REQUEST_SUCCESS,
});

const signupFailed = () => ({
  type: AUTH_SIGN_UP_FAILED,
});

export const handleAddMember =
  ({ email, organization, firstName, lastName }) =>
  async (dispatch) => {
    dispatch(addMemberRequest());
    try {
      const user = await authRef.createUserWithEmailAndPassword(
        email,
        DEFAULT_PASSWORD
      );
      const updateUser = authRef.currentUser;
      updateUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      await orgRef.doc(updateUser.uid).set({ organization });

      await sendEmailTo({
        to: email,
        from: {
          email: process.env.REACT_APP_ADMIN_ACCOUNT,
          name: "Cauzality",
        },
        subject: "Welcome to Cauzality",
        html: `
          Hello ${firstName}!<br />
          <br />
          Welcome to Cauzality -- an online, collaborative tool to create knowledge maps. Use our innovative tool to explore causal relationships and discover the most effective ways to improve results.<br />
          <br />
          Please use the link below to finish setting up your account and then you'll be ready to start mapping!<br />
          <br />
          <a href="${process.env.REACT_APP_PUBLIC_URL}/finishSignUp/${user.user.uid}/${user.user.email}">Get Started</a><br />
          <br />
          We’re here to support your success. If you need any help, just hit 'Reply' and let us know how we can assist.<br />
          <br />
          - Cauzality Team
        `,
      });
      dispatch(addMemberSuccess());
    } catch (err) {
      dispatch(addMemberFailed());
    }
  };

const addMemberRequest = () => ({
  type: AUTH_ADD_MEMBER_REQUEST,
});

const addMemberSuccess = () => ({
  type: AUTH_ADD_MEMBER_SUCCESS,
});

const addMemberFailed = () => ({
  type: AUTH_ADD_MEMBER_FAILED,
});

export const confirmSignIn = (email, uid, newPassword) => async (dispatch) => {
  dispatch(confirmSignInRequest());

  try {
    const oldUser = await authRef.signInWithEmailAndPassword(
      email,
      DEFAULT_PASSWORD
    );
    if (oldUser && oldUser.user.uid === uid) {
      const updateUser = authRef.currentUser;
      await updateUser.updatePassword(newPassword);
      const finishedSignUpDoc = await usersRef.doc("finishedAt");
      await finishedSignUpDoc.update({ [updateUser.uid]: new Date() });
      dispatch(confirmSignInSuccess());
    } else {
      dispatch(confirmSignInFailed());
    }
  } catch (err) {
    dispatch(confirmSignInFailed());
  }
};

const confirmSignInRequest = () => ({
  type: COMPLETE_SIGNUP_REQUEST,
});

const confirmSignInSuccess = () => ({
  type: COMPLETE_SIGNUP_SUCCESS,
});

const confirmSignInFailed = () => ({
  type: COMPLETE_SIGNUP_FAILED,
});

export const handlePasswordChange =
  (userInfo, newPassword) => async (dispatch) => {
    dispatch(confirmSignInRequest());

    try {
      const newUserInfo = {
        email: userInfo.email,
        password: newPassword,
        displayName: userInfo.displayName,
        emailVerified: userInfo.emailVerified,
        uid: userInfo.uid,
      };
      await updateUserInfo(newUserInfo);
      dispatch(confirmSignInSuccess());
    } catch (err) {
      dispatch(confirmSignInFailed());
    }
  };

export const handleColorChange = (avatar_color, uid) => async (dispatch) => {
  try {
    const newUserInfo = {
      avatar_color: avatar_color,
      uid: uid,
    };
    await avatarColorRef
      .doc(uid)
      .set(newUserInfo)
      .then(async () => await dispatch(getUsreAvatarColor(uid)));
  } catch (err) {
    dispatch({ type: UPLOAD_AVATAR_COLOR_FAILED });
  }
};

export const getUsreAvatarColor = (uid) => async (dispatch) => {
  const avatarColorSnapShot = await avatarColorRef.get();

  const avatarColor = [];

  avatarColorSnapShot.docs.map((doc) => avatarColor.push(doc.data()));

  const finalAvatarColor = avatarColor.filter((data) => {
    return uid === data.uid;
  });
  await dispatch({
    type: UPLOAD_AVATAR_COLOR_SUCCESSED,
    payload: finalAvatarColor[0],
  });
};

export const setAdminMode = (b) => ({
  type: SET_ADMIN_MODE,
  payload: b,
});

export const logout = () => async (dispatch) => {
  await dispatch(loginRequest());
  try {
    await authRef.signOut();
    dispatch(clearMaps());
    dispatch(logoutSuccess());
  } catch (err) {
    dispatch(logoutSuccess());
  }
};

const logoutSuccess = () => ({
  type: AUTH_LOGOUT,
});

export const saveSubscription = (payload) => ({
  type: SAVE_USER_SUBSCRIPTION,
  payload,
});
