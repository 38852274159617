import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Drawer, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NotesItem from "./NotesItem";
import "../../styles/components/NotesBar/NotesBar.scss";
import { setnoteFocus } from "../../redux/actions/noteAction";

const NoteBar = ({ open, onClose, addNote }) => {
  const { noteHistory, notefocus } = useSelector((state) => state.note);
  const dispatch = useDispatch();
  const [all_Notefocus, Setall_Notefocus] = useState(false);

  const handleChangeVisibility = (item) => {
    if (notefocus === undefined) {
      dispatch(setnoteFocus([item]));
    } else {
      const fIndex = notefocus?.findIndex(
        (fNote) => JSON.stringify(fNote.saveId) === JSON.stringify(item.saveId)
      );

      let allNotefocus = [];
      if (fIndex > -1) {
        allNotefocus = notefocus.filter(
          (fNote) =>
            JSON.stringify(fNote.saveId) !== JSON.stringify(item.saveId)
        );
        if (allNotefocus.length === 0) {
          dispatch(setnoteFocus(null));
        } else {
          dispatch(setnoteFocus(allNotefocus));
        }
      } else {
        allNotefocus = [...notefocus, item];
        dispatch(setnoteFocus(allNotefocus));
      }
    }
  };
  const show_all_notes = (data) => {
    Setall_Notefocus(data);
    if (data) {
      dispatch(setnoteFocus(noteHistory));
    } else {
      dispatch(setnoteFocus(null));
    }
  };
  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      variant="persistent"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <DragDropContext style={{ overFlow: "visible" }}>
        <div className="chatbar-container">
          <section className="chatbar-title">
            <h2 className="title title-sidebar">Sticky notes</h2>
            <div className="all_note_visiable">
              <img
                src={`/assets/ico-eye${all_Notefocus ? "-blue" : ""}.svg`}
                style={{ width: "21px", cursor: "pointer" }}
                alt=""
                onClick={() => {
                  show_all_notes(!all_Notefocus);
                }}
              />
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon className="btn-close" />
            </IconButton>
          </section>
          <Droppable droppableId="messagelist">
            {(provided) => (
              <section
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="messagelist-container"
              >
                {noteHistory &&
                  noteHistory.map((item, index) => (
                    <NotesItem
                      key={index}
                      index={index}
                      data={item}
                      onChangeVisibility={() => handleChangeVisibility(item)}
                    />
                  ))}
                {provided.placeholder}
              </section>
            )}
          </Droppable>
          <section className="add-notes">
            <img src="/assets/add_note.svg" alt="center" onClick={addNote} />
          </section>
        </div>
      </DragDropContext>
    </Drawer>
  );
};
export default NoteBar;
