import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./style.scss";
import { content } from "./data/index";
const CrudModal = ({ index, show, handleSubmit, handleCloseModal, data }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        onClose={handleCloseModal}
        // maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        className="dailog-box"
      >
        <DialogTitle id="">{show && content[index].title}</DialogTitle>
        <DialogContent className="dailog">
          <DialogContentText>
            {index === 3 ? (
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    show && data.creator.length >= 2
                      ? content[index].p
                      : content[index].p1,
                }}
              />
            ) : index === 4 ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: show && content[index].p,
                }}
              />
            ) : (
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      show && data?.cloning_users?.user_id?.length > 0
                        ? content[index].updaedp
                        : content[index].p,
                  }}
                />
                <p
                  className="crud-modal-text"
                  dangerouslySetInnerHTML={{
                    __html: show && content[index].p1,
                  }}
                />
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <div className="dailog-btn">
          <Button onClick={handleSubmit} className="green-btn">
            {[2, 3].includes(index)
              ? "Delete"
              : [4, 5].includes(index)
              ? "Ok"
              : "Got it"}
          </Button>
          {[2, 3, 4, 5, 6].includes(index) ? (
            <Button onClick={handleCloseModal} className="close-btn">
              {index === 2 ? "Nevermind" : "CANCEL"}
            </Button>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </div>
  );
};
export default CrudModal;
