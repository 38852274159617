import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { TextField, Tooltip } from "@material-ui/core";

import "../../styles/components/FeedbackBar/FeedbackLoopItem.scss";

const FeedbackLoopItem = ({
  data,
  focusedCycle,
  loopView,
  onChangeView,
  onChangeName,
  onhandleBlur,
  onChangeVisibility,
  index,
  // onPlayLoopOnce,
  // onPlayLoopRepeat
}) => {
  const [isEditing, setEditing] = useState(false);
  const { hoverStatus } = useSelector((state) => state.node);
  // const [openDrop, openDropdown] = useState(null);
  // useEffect(() => {
  //   const onScroll = () => {
  //     if (openDrop === "show") {
  //       document.getElementById("global-backdrop").style.display = "none";
  //       openDropdown(null);
  //     }
  //   };
  //   document.getElementById("global-backdrop").addEventListener("click", onScroll);

  //   return () => {
  //     document.getElementById("global-backdrop").removeEventListener("click", onScroll);
  //   };
  // });
  const isSelected = useMemo(() => {
    if (data && hoverStatus) {
      return data.data.includes(hoverStatus);
    }
    return false;
  }, [data, hoverStatus]);

  const getUrl = () => {
  if (!focusedCycle || !loopView) {
      return "/assets/ico-eye.svg";
    }

    if (
      focusedCycle?.findIndex(
        (fcycle) => JSON.stringify(fcycle.data) === JSON.stringify(data.data)
      ) < 0
    ) {
      return "/assets/ico-eye-close.svg";
    }

    return "/assets/ico-eye-blue.svg";
  };

  const handleBlur = () => {
    onhandleBlur();
    setEditing(false);
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      handleBlur();
    }
  };
  // const showDrondown = () => {
  //   if (openDrop == null) {
  //     document.getElementById("global-backdrop").style.display = "block";
  //     openDropdown("show");
  //   } else {
  //     document.getElementById("global-backdrop").style.display = "none";
  //     openDropdown(null);
  //   }
  // };
  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={isSelected ? "feedback-item-selected" : "feedback-item"}
        >
          <span className="feedback-item-number" {...provided.dragHandleProps}>
            {index + 1}
          </span>
          <div className="feedback-item-body">
            {isEditing ? (
              <TextField
                onKeyPress={handleKeyPress}
                onBlur={handleBlur}
                value={data.name}
                autoFocus
                onChange={onChangeName}
              />
            ) : (
              <Tooltip title={data.name} leaveDelay={200}>
                <p
                  onClick={() => setEditing(true)}
                  className="feedback-loop-name"
                >
                  {data.name}
                </p>
              </Tooltip>
            )}
            <p className="feedback-loop-type">{data.type}</p>
          </div>
          <div className="feedback-item-action">
            <Tooltip title="Show Loop" leaveDelay={200}>
              <button
                className="feedback-item-button"
                onClick={onChangeVisibility}
              >
                <img src={getUrl()} alt="center" style={{ width: 18 }} />
              </button>
            </Tooltip>
            <Tooltip title="Zoom to Fit" leaveDelay={200}>
              <button className="feedback-item-button" onClick={onChangeView}>
                <img src="/assets/ico-center.svg" alt="center" />
              </button>
            </Tooltip>
            {/* <div className="dropdown" style={{float:"right"}}>
              <button className="dropbtn feedback-item-button">
                <img src="/assets/ico-down-arrow.svg" alt=""/> onClick={() => showDrondown(this)}
              </button>
              <div className={`dropdown-content ${openDrop}`}>
                <span onClick={()=>{onChangeView()}}><img src="/assets/ico-center.svg"/> <a>Zoom to Fit</a></span>
                <span onClick={()=>{onPlayLoopOnce()}}><img src="/assets/ico-Play.svg" alt=""/> <a>Play Loop (Once)</a></span>
                <span onClick={()=>{onPlayLoopRepeat()}}><img src="/assets/ico-Play.svg" alt=""/> <a>Play Loop (Repeat)</a></span>
                <span><img src="/assets/notes.svg" alt=""/> <a>Notes</a></span>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default FeedbackLoopItem;
